import { Trans, useTranslation } from "next-i18next";
import { bool, func, object, string } from "prop-types";
import React from "react";

import Button from "~components/Button";
import ErrorMessage from "~components/ErrorMessage";
import Checkbox from "~components/forms/Checkbox";
import TextInput from "~components/forms/TextInput";
import classnames from "~utils/classnames";
import {
  EMAIL_VALIDATION_REGEX,
  HOUSE_VALIDATION_REGEX,
  POSTALCODE_VALIDATION_REGEX,
} from "~utils/regex";

import classes from "../../RegistrationForm.module.scss";

export const RegistrationView = ({
  onSubmit,
  errors,
  isLoading,
  register,
  promotionalConditionsUrl,
}) => {
  const { t } = useTranslation();

  return (
    <form className={classes.registrationForm} onSubmit={onSubmit}>
      <fieldset>
        <legend>{t("contactDetails")}</legend>
        <TextInput
          id="Firstname"
          name="Firstname"
          hasError={!!errors.Firstname}
          errorMessage={errors.Firstname?.message}
          label={t("forms:label.promotionFirstName")}
          {...register("Firstname", { required: false })}
        />
        <TextInput
          id="Lastname"
          name="Lastname"
          hasError={!!errors.Lastname}
          errorMessage={errors.Lastname?.message}
          label={t("forms:label.promotionLastName")}
          {...register("Lastname", { required: false })}
        />
      </fieldset>
      <fieldset>
        <TextInput
          id="Email"
          name="Email"
          hasError={!!errors.Email}
          errorMessage={errors.Email?.message}
          label={t("forms:label.promotionEmail")}
          {...register("Email", {
            required: {
              value: true,
              message: t("forms:validation.required", {
                field: t("forms:label.promotionEmail"),
              }),
            },
            pattern: {
              value: EMAIL_VALIDATION_REGEX,
              message: t("forms:validation.email", {
                field: t("forms:label.promotionEmail"),
              }),
            },
          })}
        />
        <TextInput
          id="Postcode"
          name="Postcode"
          placeholder="1000 AA"
          hasError={!!errors.Postcode}
          errorMessage={errors.Postcode?.message}
          label={t("forms:label.promotionPostal")}
          {...register("Postcode", {
            required: {
              value: true,
              message: t("forms:validation.required", {
                field: t("forms:label.promotionPostal"),
              }),
            },
            pattern: {
              value: POSTALCODE_VALIDATION_REGEX,
              message: t("forms:validation.postcode", {
                field: t("forms:label.promotionPostal"),
              }),
            },
          })}
        />
      </fieldset>
      <fieldset>
        <TextInput
          id="Huisnummer"
          name="Huisnummer"
          hasError={!!errors.Huisnummer}
          errorMessage={errors.Huisnummer?.message}
          label={t("forms:label.house")}
          {...register("Huisnummer", {
            required: {
              value: true,
              message: t("forms:validation.required", {
                field: t("forms:label.house"),
              }),
            },
            pattern: {
              value: HOUSE_VALIDATION_REGEX,
              message: t("forms:validation.house", {
                field: t("forms:label.house"),
              }),
            },
          })}
        />
        <TextInput
          id="Toevoeging"
          name="Toevoeging"
          hasError={!!errors.Toevoeging}
          errorMessage={errors.Toevoeging?.message}
          label={t("forms:label.houseAddition")}
          max="20"
          {...register("Toevoeging", {
            required: false,
          })}
        />
      </fieldset>

      <fieldset className={classnames([classes.terms, classes.terms_vertical])}>
        <Checkbox
          id="UitmailOptin"
          name="UitmailOptin"
          label={
            <>
              <span>{t("forms:label.promotionOutmail")}</span>
              <br />
              <small>{t("forms:label.promotionOutmailInfo")}</small>
            </>
          }
          hasError={!!errors.UitmailOptin}
          errorMessage={errors.UitmailOptin?.message}
          {...register("UitmailOptin", { required: false })}
        />
        <Checkbox
          id="OnderzoekOptin"
          name="OnderzoekOptin"
          label={
            <>
              <span>{t("forms:label.promotionResearch")}</span>
              <br />
              <small>{t("forms:label.promotionResearchInfo")}</small>
            </>
          }
          hasError={!!errors.OnderzoekOptin}
          errorMessage={errors.OnderzoekOptin?.message}
          {...register("OnderzoekOptin", { required: false })}
        />
        <Checkbox
          id="TermsAccepted"
          name="TermsAccepted"
          label={
            <Trans t={t} i18nKey="forms:label.promotionTerms">
              I have read and accept the{" "}
              <a href={promotionalConditionsUrl}>Promotional Conditions</a>.
            </Trans>
          }
          hasError={!!errors.TermsAccepted}
          errorMessage={errors.TermsAccepted?.message}
          className={classes.bold}
          {...register("TermsAccepted", {
            required: {
              value: true,
              message: t("forms:validation.promotionTerms"),
            },
          })}
        />
        <input
          type="hidden"
          name="Actief"
          value={true}
          {...register("Actief", {
            required: false,
          })}
        />
      </fieldset>
      {Object.keys(errors).length > 0 && (
        <div className={classes.formErrors}>
          <ErrorMessage message={t("forms:validation.errorsInForm")} />
        </div>
      )}
      <Button type="submit" color={"secondary"} isLoading={isLoading}>
        {t("requestPromotionalCode")}
      </Button>
      <p className={classes.recaptchaNotice}>
        <Trans t={t} i18nKey="forms:label.recaptchaNotice">
          Dit formulier maakt gebruik van reCAPTCHA. De{" "}
          <a href="https://policies.google.com/privacy">
            Google Privacyvoorwaarden
          </a>
          en <a href="https://policies.google.com/terms">Servicevoorwaarden</a>{" "}
          zijn van toepassing.
        </Trans>
      </p>
    </form>
  );
};

RegistrationView.propTypes = {
  onSubmit: func.isRequired,
  errors: object.isRequired,
  isLoading: bool.isRequired,
  register: func.isRequired,
  promotionalConditionsUrl: string.isRequired,
};

export default RegistrationView;
