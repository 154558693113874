import { getCookie } from "cookies-next";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";
import { shape, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import DuoColorText from "~components/DuoColorText";
import ErrorMessage from "~components/ErrorMessage";
import { PREPR_CUSTOMER_COOKIE_NAME } from "~config/prepr";
import getNeighborhood from "~lib/getNeighborhood";
import { contextErrorLogger } from "~lib/logging";
import classnames from "~utils/classnames";
import getVariable from "~utils/getVariable";

import { registerCustomer, requestEmail } from "./api";
import classes from "./RegistrationForm.module.scss";
import { ReclaimView } from "./Views/Reclaim";
import RegistrationView from "./Views/Registration";
import { SuccessView } from "./Views/Success";

const logError = contextErrorLogger("registration-form");

const { publicRuntimeConfig } = getConfig();

const { environment } = publicRuntimeConfig;

const recaptchaEnabled = getVariable("recaptchaEnabled");

const openErrorToast = (message) => {
  toast(<ErrorMessage variant="brand" message={message} isToast centered />);
};

const RegistrationForm = ({
  title: defaultTitle,
  titleEmphasis: defaultTitleEmphasis,
  body: defaultBody,
  successTitle,
  successEmphasis,
  successBody,
  reclaimTitle,
  reclaimEmphasis,
  reclaimBody,
  promotionalConditionsDocument,
}) => {
  const [currentView, setCurrentView] = useState("registration");
  const [isLoading, setIsLoading] = useState(false);
  const viewRef = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const preprUid = getCookie(PREPR_CUSTOMER_COOKIE_NAME);
  const router = useRouter();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const isDevelopment = environment === "development";

  async function handleRegistrationApiCall(apiFunction, dataWithToken) {
    const result = await apiFunction(dataWithToken);

    if (result?.status === 200) {
      if (isDevelopment) {
        console.log(result);
      }
      return result.response;
    }
    if (result?.status === 409) {
      throw new Error(
        isDevelopment
          ? result?.response?.Message
          : t("errors:registrationFailed.userAlreadyExists"),
      );
    }

    throw new Error(
      isDevelopment
        ? result?.response?.Message
        : t("errors:registrationFailed.general"),
    );
  }

  async function handleReclaimApiCall(apiFunction, dataWithToken) {
    const result = await apiFunction(dataWithToken);

    if (result?.status === 200) {
      if (isDevelopment) {
        console.log(result);
      }
      return result.response;
    }

    throw new Error(
      isDevelopment
        ? result?.response?.Message
        : t("errors:resendCodeFailed.general"),
    );
  }

  async function onSubmit(data) {
    try {
      if (recaptchaEnabled) {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }
      }
      setIsLoading(true);

      const token = recaptchaEnabled
        ? await executeRecaptcha("onSubmit")
        : null;
      const dataWithToken = {
        ...data,
        Postcode: data.Postcode.replace(/\s+/g, "").toUpperCase(),
        LanguageCode: router.locale,
        captchaToken: token,
      };

      if (currentView === "reclaim") {
        await handleReclaimApiCall(requestEmail, dataWithToken);
      }

      if (currentView === "registration") {
        const response = await handleRegistrationApiCall(
          registerCustomer,
          dataWithToken,
        );

        if (response.customer?.Postcode) {
          const neighborhoods = getNeighborhood(response.customer.Postcode);

          if (neighborhoods.length && preprUid) {
            // The endpoint will continue after refresh and the result of this call is not relevant for the user.
            await fetch(`/api/customer/${preprUid}/update-tags`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ tags: neighborhoods }),
            });
          }
        }
      }

      setCurrentView("success");
      viewRef.current.scrollIntoView();
    } catch (error) {
      logError(error);
      openErrorToast(error?.message);
      if (isDevelopment) {
        console.error("Error in onSubmit:", error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const viewData = {
    registration: {
      title: defaultTitle,
      titleEmphasis: defaultTitleEmphasis,
      body: defaultBody,
      changeForm: "forms:label.goToReclaim",
      nextView: "reclaim",
      component: RegistrationView,
    },
    reclaim: {
      title: reclaimTitle,
      titleEmphasis: reclaimEmphasis,
      body: reclaimBody,
      changeForm: "forms:label.goToRegister",
      nextView: "registration",
      component: ReclaimView,
    },
    success: {
      title: successTitle,
      titleEmphasis: successEmphasis,
      body: successBody,
      component: SuccessView,
    },
  };

  const {
    title,
    titleEmphasis,
    body,
    changeForm,
    nextView,
    component: ViewComponent,
  } = viewData[currentView];

  const changeView = (event) => {
    event.preventDefault();
    setCurrentView(nextView);
    viewRef.current.scrollIntoView();
  };

  return (
    <section ref={viewRef} className={classes.block}>
      {title && (
        <h3 className={classes.header_2}>
          <DuoColorText text={title} emphasis={titleEmphasis} />
        </h3>
      )}
      {body && (
        <p className={classnames([classes.body, classes.paragraph])}>{body}</p>
      )}

      {changeForm && (
        <div className={classes.changeForm}>
          <Trans t={t} i18nKey={changeForm}>
            Already registered but lost your code?{" "}
            <a href="#" onClick={changeView}>
              Reclaim your promotional code.
            </a>
          </Trans>
        </div>
      )}

      <ViewComponent
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        errors={errors}
        register={register}
        promotionalConditionsUrl={promotionalConditionsDocument?.url}
      />
    </section>
  );
};

export const RegistrationFormType = {
  id: string,
  title: string,
  titleEmphasis: string,
  body: string,
  successTitle: string,
  successEmphasis: string,
  successBody: string,
  reclaimTitle: string,
  reclaimEmphasis: string,
  reclaimBody: string,
  promotionalConditionsDocument: shape({
    url: string.isRequired,
  }),
};

RegistrationForm.propTypes = RegistrationFormType;

export const RegistrationFormFragment = /* GraphQL */ `
  fragment RegistrationFormFragment on RegistrationForm {
    id: _id
    type: __typename
    title
    titleEmphasis: title_emphasis
    body
    successTitle: success_title
    successEmphasis: success_title_emphasis
    successBody: success_body
    reclaimTitle: reclaim_title
    reclaimEmphasis: reclaim_title_emphasis
    reclaimBody: reclaim_body
    promotionalConditionsDocument: promotional_conditions_document {
      url
    }
  }
`;

export default RegistrationForm;
