import { useTranslation } from "next-i18next";
import { arrayOf, bool, node, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import HorizontalScrollbar from "~components/HorizontalScrollbar";
import IconButton from "~components/IconButton";
import classnames from "~utils/classnames";

import classes from "./ItemRow.module.scss";

const ItemRow = ({
  children,
  inline,
  showArrows = true,
  className,
  barClassname,
}) => {
  const { t } = useTranslation();
  const [overflow, setOverflow] = useState(false);
  const containerRef = useRef(null);
  const [scrollState, setScrollState] = useState({
    prevEnabled: false,
    nextEnabled: true,
  });

  const rowClassname = classnames(
    classes[`row${overflow ? "_overflow" : ""}`],
    inline && classes.row_inline,
  );

  const updateScrollState = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const containerWidth = container.getBoundingClientRect().width;
    const scrollWidth = container.scrollWidth;
    const scrollBarEnabled = scrollWidth > containerWidth;

    setOverflow(scrollBarEnabled);
    setScrollState({
      prevEnabled: container.scrollLeft > 0,
      nextEnabled: container.scrollLeft + containerWidth <= scrollWidth - 1,
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver(updateScrollState);
    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, []);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const containerWidth = container.getBoundingClientRect().width;
    const newScrollLeft = Math.max(
      0,
      (container.scrollLeft += direction * containerWidth),
    );
    container.scrollLeft = newScrollLeft;

    const scrollWidth = container.scrollWidth;

    setScrollState({
      prevEnabled: newScrollLeft > 0,
      nextEnabled: newScrollLeft + containerWidth <= scrollWidth - 1,
    });
  };

  return (
    <div className={classnames(classes.ItemRow, className)}>
      <div ref={containerRef} className={rowClassname}>
        {children}
      </div>
      {overflow && (
        <>
          <HorizontalScrollbar
            containerRef={containerRef}
            barClassname={barClassname}
          />

          {showArrows && scrollState.prevEnabled && (
            <IconButton
              disabled={!scrollState.prevEnabled}
              className={classes.arrowLeft}
              icon="arrowLeftThin"
              onClick={() => handleScroll(-1)}
              title={t("previous")}
            />
          )}

          {showArrows && scrollState.nextEnabled && (
            <IconButton
              disabled={!scrollState.nextEnabled}
              className={classes.arrowRight}
              icon="arrowRightThin"
              onClick={() => handleScroll(1)}
              title={t("next")}
            />
          )}
        </>
      )}
    </div>
  );
};

ItemRow.propTypes = {
  children: arrayOf(node).isRequired,
  showArrows: bool,
  inline: bool,
  className: string,
  barClassname: string,
};

export default ItemRow;
