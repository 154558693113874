import { arrayOf, func, number, shape, string } from "prop-types";

import Button from "~components/Button";
import DuoColorText from "~components/DuoColorText";
import ProductCard from "~components/ProductCard";
import classnames from "~utils/classnames";
import { formatCurrency } from "~utils/format";

import classes from "./ProductSelectorBlock.module.scss";

const ProductSelectorBlock = ({
  id,
  title,
  titleEmphasis,
  subtitle,
  productGroupTitle,
  products,
  totalPrice,
  onProductQuantityChange,
  onCheckout,
  totalLabel,
  checkoutLabel,
}) => {
  const narrowContainer = products.length <= 2;

  const totalLabelAria = `${totalLabel}: ${formatCurrency(totalPrice)}`;

  return (
    <section id={id} aria-labelledby={`${id}-heading`}>
      <div className={classes.heading}>
        <h2 id={`${id}-heading`}>
          <DuoColorText text={title} emphasis={titleEmphasis} />
        </h2>
        {subtitle && <p>{subtitle}</p>}
      </div>

      <ul
        className={classnames(
          classes.products,
          narrowContainer && classes.products_narrow,
        )}
      >
        {products.map((product) => (
          <li
            key={product.productId}
            className={classnames(
              classes.productWrapper,
              narrowContainer && classes.productWrapper_narrow,
            )}
          >
            <ProductCard
              title={product.title}
              subtitle={product.subtitle || ""}
              beforePrice={product.oldPrice || 0}
              price={product.price}
              defaultQuantity={product.quantity || 0}
              onQuantityChange={(quantity) =>
                onProductQuantityChange(product.productId, quantity)
              }
            />
          </li>
        ))}
      </ul>
      <div className={classes.pricingFooter}>
        <div>
          {products
            .filter((product) => product.quantity > 0)
            .map((product) => (
              <div key={product.productId}>
                <strong>
                  {product.quantity} x {product.title}
                </strong>{" "}
                {productGroupTitle}
              </div>
            ))}
        </div>
        <div className={classes.checkoutButtonWrapper}>
          <div>
            <span aria-live="polite" aria-label={totalLabelAria}>
              {totalLabel} {formatCurrency(totalPrice)}
            </span>
          </div>
          <Button
            onClick={onCheckout}
            color={"secondary"}
            disabled={totalPrice === 0}
            aria-label={checkoutLabel}
            aria-disabled={totalPrice === 0}
          >
            {checkoutLabel}
          </Button>
        </div>
      </div>
    </section>
  );
};

ProductSelectorBlock.propTypes = {
  id: string,
  title: string.isRequired,
  titleEmphasis: string,
  subtitle: string,
  productGroupTitle: string.isRequired,
  products: arrayOf(
    shape({
      productId: string.isRequired,
      title: string.isRequired,
      subtitle: string,
      price: number.isRequired,
      quantity: number,
    }),
  ).isRequired,
  totalPrice: number.isRequired,
  onProductQuantityChange: func.isRequired,
  onCheckout: func.isRequired,
  totalLabel: string.isRequired,
  checkoutLabel: string.isRequired,
};

export default ProductSelectorBlock;
