export const CURRENCY = {
  EUR: "EUR",
  USD: "USD",
};

const CURRENCY_TO_LOCALE = {
  EUR: "nl-NL",
  USD: "en-US",
};

/**
 *
 * @param {number|| string} number
 * @param {string} currency
 * @param {boolean} trimTrailingZeros
 * @return {string}
 * @example
 * formatCurrency(108, "EUR")
 * // returns € 108,00
 */
export const formatCurrency = (
  number,
  currency = CURRENCY.EUR,
  trimTrailingZeros = false,
) =>
  Intl.NumberFormat(CURRENCY_TO_LOCALE[currency] ?? "nl-NL", {
    style: "currency",
    currency,
    maximumFractionDigits: !trimTrailingZeros || (number | 0) < number ? 2 : 0,
  }).format(typeof number === "number" ? number : parseFloat(number));
