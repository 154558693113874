import { arrayOf, shape, string } from "prop-types";
import React from "react";

import Link from "~components/Link";
import ArrowRight from "~public/icons/arrow-right.svg";
import { toValidUrl } from "~utils/routing";

import classes from "./TasksBlock.module.scss";

const TasksBlock = ({ title, lists }) => (
  <section>
    <h2 className={classes.title}>{title}</h2>
    <div className={classes.lists}>
      {lists.map((list) => (
        <div key={list.id} className={classes.list}>
          <h3 className={classes.listTitle}>
            {list.contentSlug?.[0]?.slug ? (
              <>
                <Link
                  referenceId={list.id}
                  href={toValidUrl(`/${list.contentSlug[0].slug}`)}
                >
                  {list.title}
                </Link>
                <ArrowRight />
              </>
            ) : (
              list.title
            )}
          </h3>
          <p className={classes.listDescription}>{list.description}</p>
          <ul className={classes.listLinks}>
            {list.links.map(
              (link) =>
                !!link.slug && (
                  <li key={link.id} className={classes.link}>
                    <Link
                      referenceId={link.id}
                      href={toValidUrl(`/${link.slug}`)}
                    >
                      {link.title}
                    </Link>
                  </li>
                ),
            )}
          </ul>
        </div>
      ))}
    </div>
  </section>
);

export const TasksBlockType = {
  id: string,
  type: string,
  title: string,
  lists: arrayOf(
    shape({
      id: string,
      title: string,
      description: string,
      contentSlug: arrayOf(
        shape({
          slug: string,
        }),
      ),
      links: arrayOf(
        shape({
          id: string,
          title: string,
          slug: string,
        }),
      ),
    }),
  ),
};

TasksBlock.propTypes = TasksBlockType;

export const TasksBlockFragment = /* GraphQL */ `
  fragment TasksBlockFragment on TasksBlock {
    id: _id
    type: __typename
    title
    lists {
      id: _id
      title
      description
      contentSlug: content_slug {
        slug: _slug
      }
      links {
        ... on OverviewPage {
          id: _id
          type: __typename
          title
          slug: _slug
        }
        ... on Article {
          type: __typename
          id: _id
          title
          slug: _slug
        }
        ... on ProductSelectorPage {
          id: _id
          type: __typename
          title
          slug: _slug
        }
      }
    }
  }
`;
export default TasksBlock;
