/**
 * Generates a color index from a given string and seed using a hash function.
 *
 * @param {string} str - The string to be hashed.
 * @param {number} seed - The seed to influence the hash.
 * @param {number} [colorCount=4] - The number of color indexes (defaults to 4).
 * @returns {number} - The color index (1-colorCount).
 *
 * @example
 * // Basic usage with default color count
 * const colorIndex = hashIdToColorIndex('example', 1234);
 * console.log(colorIndex); // Output: a number between 1 and 4
 *
 * @example
 * // Usage with custom color count
 * const colorIndex = hashIdToColorIndex('example', 1234, 6);
 * console.log(colorIndex); // Output: a number between 1 and 6
 *
 * @example
 * // Consistent results with the same input
 * const colorIndex1 = hashIdToColorIndex('example', 1234);
 * const colorIndex2 = hashIdToColorIndex('example', 1234);
 * console.log(colorIndex1 === colorIndex2); // Output: true
 *
 * @example
 * // Different seed results in different color index
 * const colorIndex1 = hashIdToColorIndex('example', 1234);
 * const colorIndex2 = hashIdToColorIndex('example', 5678);
 * console.log(colorIndex1 !== colorIndex2); // Output: true
 */

const hashIdToColorIndex = (str, seed, colorCount = 4) => {
  let hash = seed | 0; // Ensure seed is a 32-bit integer
  for (let i = 0; i < str.length; i++) {
    hash = Math.imul(hash, 31) + str.charCodeAt(i); // Use Math.imul for 32-bit integer multiplication
    hash = hash | 0; // Convert to 32-bit integer
    hash = hash ^ (hash >>> 16); // Further mixing
  }
  return (Math.abs(hash) % colorCount) + 1;
};

export default hashIdToColorIndex;
