import { useTranslation } from "next-i18next";
import { shape, string } from "prop-types";
import { useState } from "react";

import Logo from "~components/750/logo";
import Button from "~components/Button";
import { EventCardType } from "~components/Cards/EventCard";
import Countdown from "~components/Countdown";
import ImageComponent from "~components/ImageComponent";
import { useTheme } from "~contexts/ThemeContext";

import classes from "./CountdownBlock.module.scss";

const CountdownBlock = ({
  title,
  preTitle,
  description,
  link,
  label,
  date,
  event,
}) => {
  const currentEvent = event?.[0];
  const { theme } = useTheme();
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(true);

  const handleCountdownEnd = () => {
    setIsVisible(false);
  };

  if (!currentEvent || !isVisible) {
    return null;
  }

  return (
    <>
      <section className={classes.countdown}>
        <div className={classes.content}>
          <h2 className={classes.title}>{title}</h2>
          <h3 className={classes.preTitle}>{preTitle}</h3>
          <p className={classes.description}>{description}</p>
          {link?.[0]?.slug && label && (
            <Button
              href={link[0].slug}
              className={classes.button}
              color={theme === "750" ? "white" : "secondary"}
              hasTheme
            >
              {label}
            </Button>
          )}
        </div>
        <div className={classes.event}>
          {!!currentEvent?.images?.length && (
            <ImageComponent
              src={currentEvent.images?.[0].src}
              width={584}
              height={240}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              objectFit="cover"
              className={classes.image}
            />
          )}
          <h3 className={classes.title}>
            {currentEvent.name}
            <span>{t("countDownPrefix")}</span>
          </h3>
          <Countdown date={date} onCountdownEnd={handleCountdownEnd} />
          <Button href={currentEvent.slug} className={classes.button}>
            {t("countDownLabel")}
          </Button>
          <Logo className={classes.logo} />
        </div>
      </section>
      <div className={classes.divider} />
    </>
  );
};

CountdownBlock.propTypes = {
  title: string.isRequired,
  preTitle: string,
  description: string,
  date: string.isRequired,
  label: string,
  link: shape({
    slug: string,
  }),
  event: shape(EventCardType),
};

export const CountdownFragment = /* GraphQL */ `
  fragment CountdownFragment on Countdown {
    id: _id
    type: __typename
    title
    preTitle: pre_title
    description
    date
    label
    link {
      ... on OverviewPage {
        slug: _slug
      }
    }
    event {
      ... on Event {
        ...EventCardFragment
      }
    }
  }
`;

export default CountdownBlock;
