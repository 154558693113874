/** @type {import("@sentry/browser").BrowserOptions} */
const sentryConfig = {
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  autoSessionTracking: false, // Disables session tracking
  integrations: (integrations) => {
    return integrations.filter((integration) => {
      //f91ad33b342b0888a117951fd13b3c8e@o245973.ingest.sentry.io/4505714313658368
      // Remove all automatic logging integrations
      return (
        integration.name !== "GlobalHandlers" &&
        integration.name !== "TryCatch" &&
        integration.name !== "Breadcrumbs" &&
        integration.name !== "LinkedErrors" &&
        integration.name !== "UserAgent" &&
        integration.name !== "Tracing"
      );
    });
  },
};

module.exports = sentryConfig;
