import Link from "next/link";
import { string } from "prop-types";

import Pill from "~components/Pill";
import hashIdToColorIndex from "~utils/hashIdToColorIndex";

import NeighbourhoodMap, { NeighbourhoodMapTypes } from "./Map";
import classes from "./NeighbourhoodMap.module.scss";

const FILTER_COLOR_SEED = 30468482;

const Neighbourhood = ({ title, description, links }) => {
  return (
    <section className={classes.neighbourhood}>
      <div className={classes.content}>
        {title && <h2 className={classes.title}>{title}</h2>}
        {description && <p className={classes.description}>{description}</p>}
      </div>
      <div className={classes.map}>
        <NeighbourhoodMap links={links} />
      </div>
      <div className={classes.buttons}>
        {links.map((item) => {
          return (
            <Link key={item.id} href={item.link} title={item.label}>
              <Pill
                className={`color-${hashIdToColorIndex(item.id, FILTER_COLOR_SEED)}`}
              >
                {item.label}
              </Pill>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export const NeighbourhoodTypes = {
  title: string,
  description: string,
  links: NeighbourhoodMapTypes.isRequired,
};

Neighbourhood.propTypes = NeighbourhoodMapTypes;

export const neighbourhoodFragment = /* GraphQL */ `
  fragment NeighbourhoodMapFragment on NeighbourhoodMap {
    id: _id
    type: __typename
    title
    description
    links {
      id: _id
      label
      link
      neighbourhood
    }
  }
`;

export default Neighbourhood;
