import Link from "next/link";
import { arrayOf, shape, string } from "prop-types";

import DuoColorText from "~components/DuoColorText";
import ImageComponent from "~components/ImageComponent";
import { ImageType } from "~types";

import classes from "./Partners.module.scss";

const PartnerCard = ({ title, partners }) => (
  <div>
    <h2 className={classes.title}>{title}</h2>
    <div className={classes.row}>
      {partners?.map((partner) =>
        partner.url ? (
          <Link key={partner.id} href={partner.url} className={classes.image}>
            <ImageComponent {...partner.image} objectFit="contain" hideAuthor />
          </Link>
        ) : (
          <div key={partner.id} className={classes.image}>
            <ImageComponent {...partner.image} objectFit="contain" hideAuthor />
          </div>
        ),
      )}
    </div>
  </div>
);

const partnerShape = shape({
  id: string.isRequired,
  url: string,
  image: shape(ImageType).isRequired,
  subtitle: string,
});

PartnerCard.propTypes = {
  title: string.isRequired,
  partners: arrayOf(partnerShape).isRequired,
};

const Partners = ({
  title,
  titleEmphasis,
  description,
  titleMain,
  mainPartner,
  titleMedia,
  mediaPartners,
  titleSocial,
  socialPartners,
  titleOther,
  otherPartners,
}) => {
  return (
    <section className={classes.partners}>
      <div className={classes.intro}>
        <DuoColorText
          text={title}
          emphasis={titleEmphasis}
          className={classes.title}
        />
        <p className={classes.description}>{description}</p>
      </div>
      <div className={classes.main}>
        {mainPartner?.length > 0 && (
          <PartnerCard title={titleMain} partners={mainPartner} />
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.column}>
          {socialPartners?.length > 0 && (
            <PartnerCard title={titleSocial} partners={socialPartners} />
          )}
          {mediaPartners?.length > 0 && (
            <PartnerCard title={titleMedia} partners={mediaPartners} />
          )}
        </div>
        {otherPartners?.length > 0 && (
          <div className={classes.column}>
            <PartnerCard title={titleOther} partners={otherPartners} />
          </div>
        )}
      </div>
    </section>
  );
};

export const PartnersType = {
  title: string.isRequired,
  titleEmphasis: string,
  description: string,
  titleMain: string,
  mainPartner: arrayOf(partnerShape),
  titleMedia: string,
  mediaPartners: arrayOf(partnerShape),
  titleSocial: string,
  socialPartners: arrayOf(partnerShape),
  titleOther: string,
  otherPartners: arrayOf(partnerShape),
};

Partners.propTypes = PartnersType;

export const PartnersFragment = /* GraphQL */ `
  fragment PartnersFragment on Partners {
    id: _id
    type: __typename
    title
    titleEmphasis: title_emphasis
    description
    titleMain: title_main
    mainPartner: main_partner {
      ...PartnerFields
    }
    titleSocial: title_social
    socialPartners: social_partners {
      ...PartnerFields
    }
    titleOther: title_other
    otherPartners: other_partners {
      ...PartnerFields
    }
    titleMedia: title_media
    mediaPartners: media_partners {
      ...PartnerFields
    }
  }
`;

export const PartnerFieldsFragment = /* GraphQL */ `
  fragment PartnerFields on Partner {
    id: _id
    company
    url
    subtitle
    image: logo_image {
      id: _id
      type: __typename
      src: url
      width
      height
      alt: description
    }
  }
`;
export default Partners;
