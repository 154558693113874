import { useTranslation } from "next-i18next";
import { bool, func, string } from "prop-types";
import { useEffect, useState } from "react";

import Button from "~components/Button";
import Icon from "~components/Icon";
import classnames from "~utils/classnames";

import classes from "./YoutubeEmbed.module.scss";

const YoutubeEmbed = ({
  title,
  embedId,
  play = false,
  onPlayChange,
  className,
}) => {
  const { t } = useTranslation();

  const [isPlaying, setIsPlaying] = useState(play);

  useEffect(() => {
    if (play === isPlaying) {
      return;
    }
    setIsPlaying(play);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play]);

  const handlePlay = () => {
    setIsPlaying(true);
    onPlayChange?.(true);
  };

  return (
    <section className={classnames(classes.videoResponsive, className)}>
      {isPlaying ? (
        <iframe
          width="860"
          height="483"
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title}
        />
      ) : (
        <div className={classes.videoThumb}>
          <div className={classes.overlay} />
          <div className={classes.overlayContent}>
            {title && <p className={classes.title}>{title}</p>}
            <Button onClick={handlePlay} className={classes.playButton}>
              <Icon type="play" />
              {t("playVideo")}
            </Button>
          </div>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={classes.thumbnail}
            src={`https://img.youtube.com/vi/${embedId}/sddefault.jpg`}
            width="100%"
            alt="Video thumbnail"
          />
        </div>
      )}
    </section>
  );
};

export const YoutubeEmbedType = {
  title: string.isRequired,
  embedId: string.isRequired,
  className: string,
  play: bool,
  onPlayChange: func,
};

YoutubeEmbed.propTypes = YoutubeEmbedType;

export const YoutubeEmbedFragment = /* GraphQL */ `
  fragment YoutubeEmbedFragment on YouTube {
    id: _id
    type: __typename
    embedId: youtube_id
    title
  }
`;

export default YoutubeEmbed;
