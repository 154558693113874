import { useTranslation } from "next-i18next";
import { arrayOf, oneOfType, shape, string } from "prop-types";
import { useEffect, useMemo, useState } from "react";

import Button from "~components/Button";
import Card from "~components/Cards";
import { EventCardType } from "~components/Cards/EventCard";
import { LocationCardType } from "~components/Cards/LocationCard";
import DuoColorText from "~components/DuoColorText";
import LocationsAndEvents from "~components/LocationsAndEvents";
import useBreakpoint from "~hooks/useBreakpoint";
import { PreprDataType } from "~types";
import classnames from "~utils/classnames";

import classes from "./LocationsAndEventsGrid.module.scss";

const breakpointFadeMapping = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 4,
};

const LocationsAndEventsGrid = ({
  title,
  titleEmphasis,
  description,
  ctaLabel,
  ctaSlug,
  items,
  preprData,
}) => {
  const [visibleCount, setVisibleCount] = useState(12);
  const { currentBreakpoint, isUpToMD } = useBreakpoint();
  const { t } = useTranslation();

  const visibleItems = useMemo(
    () => items.slice(0, visibleCount),
    [items, visibleCount],
  );

  const shouldShowLoadMore = visibleCount < items.length;

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };

  const columns = breakpointFadeMapping[currentBreakpoint] || 4;
  const lastRowStartIndex =
    Math.floor((visibleItems.length - 1) / columns) * columns;

  useEffect(() => {
    if (shouldShowLoadMore || visibleItems.length === 0) {
      return;
    }

    const firstNewItem = document.querySelector(`.${classes.card}:last-child`);
    if (firstNewItem) {
      firstNewItem.focus();
    }
  }, [shouldShowLoadMore, visibleItems.length]);

  return (
    <section className={classes.block}>
      <div className={classes.content}>
        {(title || description) && (
          <div className={classes.titleWrapper}>
            {title && (
              <h2 className={classes.header_3}>
                <DuoColorText text={title} emphasis={titleEmphasis || ""} />
              </h2>
            )}
            {description && <p className={classes.body}>{description}</p>}
          </div>
        )}
        {ctaSlug && ctaLabel && (
          <Button
            variant="outline"
            href={`/${ctaSlug}`}
            className={classes.cta}
            size="small"
          >
            {ctaLabel}
          </Button>
        )}
      </div>
      {isUpToMD() ? (
        <LocationsAndEvents items={items} />
      ) : (
        <>
          <div
            className={classnames(
              classes.items,
              shouldShowLoadMore && classes.partiallyLoaded,
            )}
          >
            {visibleItems.map((item, index) => (
              <div key={item.id} className={classes.card} tabIndex={-1}>
                <div
                  className={
                    shouldShowLoadMore && index >= lastRowStartIndex
                      ? classes.fadedCard
                      : ""
                  }
                />
                <Card preprData={preprData} {...item} type="Event" />
              </div>
            ))}
          </div>
          {shouldShowLoadMore && (
            <Button
              variant="solid"
              color="primary"
              size="small"
              onClick={handleLoadMore}
              className={classes.loadMore}
              aria-label={t("showMore")}
            >
              {t("showMore")}
            </Button>
          )}
        </>
      )}
    </section>
  );
};

export const LocationsAndEventsGridType = {
  id: string,
  title: string,
  titleEmphasis: string,
  description: string,
  ctaLabel: string,
  ctaSlug: string,
  items: arrayOf(oneOfType([shape(EventCardType), shape(LocationCardType)]))
    .isRequired,
  preprData: shape(PreprDataType),
};

LocationsAndEventsGrid.propTypes = LocationsAndEventsGridType;

export default LocationsAndEventsGrid;
