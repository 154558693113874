import { func, number, string } from "prop-types";

import IconButton from "~components/IconButton";
import classnames from "~utils/classnames";

import classes from "./QuantityPicker.module.scss";

const QuantityPicker = ({
  addValueTitle,
  removeValueTitle,
  value,
  onChange,
  minValue = 0,
  maxValue = Infinity,
}) => (
  <div
    className={classnames([
      classes.quantityPicker,
      value > 0 && classes.quantityPicker__expanded,
    ])}
  >
    {value > 0 && (
      <>
        <div
          className={classes.iconButtonWrapper}
          data-testid="quantity-button-remove"
        >
          <IconButton
            onClick={() => onChange(value - 1)}
            icon="min"
            disabled={value <= minValue}
            title={removeValueTitle}
          />
        </div>

        <span data-testid="quantity-value">{value}</span>
      </>
    )}
    <div
      className={classes.iconButtonWrapper}
      data-testid="quantity-button-add"
    >
      <IconButton
        onClick={() => onChange(value + 1)}
        icon="plus"
        disabled={value >= maxValue}
        title={addValueTitle}
      />
    </div>
  </div>
);

QuantityPicker.propTypes = {
  addValueTitle: string,
  removeValueTitle: string,
  value: number,
  onChange: func,
  minValue: number,
  maxValue: number,
};

export default QuantityPicker;
