import { string } from "prop-types";

import classnames from "~utils/classnames";

import classes from "./Logo.module.scss";

const Logo = ({ className, classNameFirst, classNameSecond }) => {
  const maskSVG =
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgyIiBoZWlnaHQ9IjE4MyIgdmlld0JveD0iMCAwIDE4MiAxODMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTM2LjQ0MiAwLjQ0MTE2OEwxODEuODgzIDQ1Ljg4MjRMMTM2LjQ0MSA5MS4zMjM5TDE4MS44ODMgMTM2Ljc2NUwxMzYuNDQyIDE4Mi4yMDdMOTEuMDAwMSAxMzYuNzY1TDQ1LjU1OSAxODIuMjA2TDAuMTE3NzEzIDEzNi43NjVMNDUuNTU4OSA5MS4zMjM5TDAuMTE3NzEzIDQ1Ljg4MjdMNDUuNTU5IDAuNDQxNDM4TDkxLjAwMDEgNDUuODgyNkwxMzYuNDQyIDAuNDQxMTY4WiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzE2MjI1XzI5NTY4KSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzE2MjI1XzI5NTY4IiB4MT0iMjI3LjMyNCIgeTE9IjkxLjMyMzkiIHgyPSItMjYuNzU2OCIgeTI9IjE2MC4wMDciIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0VBMzMyMyIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMxQjQzRjUiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K";

  return (
    <div
      className={classnames(classes.logo, className)}
      style={{
        "--mask-image-url": `url(${maskSVG})`,
      }}
    >
      <span className={classnames(classes.path, classNameFirst)}></span>
      <span className={classnames(classes.path, classNameSecond)}></span>
    </div>
  );
};

Logo.propTypes = {
  className: string,
  classNameFirst: string,
  classNameSecond: string,
};

export default Logo;
