const ImageAssetFragment = (width = 1800, height = 1020, format = "webp") => `
  id: _id
  type: __typename
  src: url(width: ${width}, height: ${height}, format: "${format}")
  width
  height
  alt: description
  author
`;

export default ImageAssetFragment;
