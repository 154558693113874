import { useTranslation } from "next-i18next";
import { bool, func, object } from "prop-types";
import React from "react";

import Button from "~components/Button";
import ErrorMessage from "~components/ErrorMessage";
import TextInput from "~components/forms/TextInput";
import {
  EMAIL_VALIDATION_REGEX,
  POSTALCODE_VALIDATION_REGEX,
} from "~utils/regex";

import classes from "../../RegistrationForm.module.scss";

export const ReclaimView = ({ onSubmit, errors, isLoading, register }) => {
  const { t } = useTranslation();

  return (
    <form className={classes.registrationForm} onSubmit={onSubmit}>
      <fieldset>
        <TextInput
          id="Email"
          name="Email"
          hasError={!!errors.Email}
          errorMessage={errors.Email?.message}
          label={t("forms:label.promotionEmail")}
          {...register("Email", {
            required: {
              value: true,
              message: t("forms:validation.required", {
                field: t("forms:label.promotionEmail"),
              }),
            },
            pattern: {
              value: EMAIL_VALIDATION_REGEX,
              message: t("forms:validation.email", {
                field: t("forms:label.promotionEmail"),
              }),
            },
          })}
        />
        <TextInput
          id="Postcode"
          name="Postcode"
          placeholder="1000 AA"
          hasError={!!errors.Postcode}
          errorMessage={errors.Postcode?.message}
          label={t("forms:label.promotionPostal")}
          {...register("Postcode", {
            required: {
              value: true,
              message: t("forms:validation.required", {
                field: t("forms:label.promotionPostal"),
              }),
            },
            pattern: {
              value: POSTALCODE_VALIDATION_REGEX,
              message: t("forms:validation.postcode", {
                field: t("forms:label.promotionPostal"),
              }),
            },
          })}
        />
      </fieldset>
      {Object.keys(errors).length > 0 && (
        <div className={classes.formErrors}>
          <ErrorMessage message={t("forms:validation.errorsInForm")} />
        </div>
      )}
      <Button type="submit" color={"black"} isLoading={isLoading}>
        {t("reclaimPromotionalCode")}
      </Button>
    </form>
  );
};

ReclaimView.propTypes = {
  onSubmit: func.isRequired,
  errors: object.isRequired,
  isLoading: bool.isRequired,
  register: func.isRequired,
};

export default ReclaimView;
