import { arrayOf, oneOf, shape, string } from "prop-types";

import Button from "~components/Button";
import DuoColorTextComponent from "~components/DuoColorText";
import ImageComponent from "~components/ImageComponent";
import ImagePlaceholder from "~components/ImagePlaceholder";
import NewIcon from "~components/NewIcon";
import ImageAssetFragment from "~fragments/ImageAssetFragment";
import { ImageType } from "~types";
import classnames from "~utils/classnames";

import classes from "./ListWithImageBlock.module.scss";

const ListWithImageBlock = ({
  title,
  titleEmphasis,
  image,
  listType = "check",
  imagePosition = "left",
  ctaLabel,
  ctaUrl,
  firstItemTitle,
  firstItemBody,
  secondItemTitle,
  secondItemBody,
  thirdItemTitle,
  thirdItemBody,
  fourthItemTitle,
  fourthItemBody,
  fifthItemTitle,
  fifthItemBody,
}) => {
  const items = [
    { title: firstItemTitle, body: firstItemBody },
    { title: secondItemTitle, body: secondItemBody },
    { title: thirdItemTitle, body: thirdItemBody },
    { title: fourthItemTitle, body: fourthItemBody },
    { title: fifthItemTitle, body: fifthItemBody },
  ];

  return (
    <section
      className={classnames(
        classes.container,
        imagePosition === "right" ? classes.reverse : "",
      )}
    >
      <div className={classes.imageColumn}>
        {image.length > 0 ? (
          <ImageComponent
            src={image[0].src}
            alt={image[0].alt}
            width={760}
            height={460}
            layout="responsive"
          />
        ) : (
          <ImagePlaceholder height={460} />
        )}
      </div>
      <div className={classes.listColumn}>
        <h2 className={classes.title}>
          <DuoColorTextComponent text={title} emphasis={titleEmphasis} />
        </h2>
        {items
          .filter(({ title }) => !!title)
          .map((item, index) => (
            <div className={classes.listItem} key={item.title}>
              <div>
                {listType === "check" && <NewIcon type="check" />}
                {listType === "number" && (
                  <span className={classes.dot}>{index + 1}</span>
                )}
              </div>
              <div>
                <h3 className={classes.listItemTitle}>{item.title}</h3>
                <p
                  className={classes.listItemBody}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              </div>
            </div>
          ))}

        {ctaUrl && ctaLabel && (
          <Button className={classes.cta} href={ctaUrl}>
            {ctaLabel}
          </Button>
        )}
      </div>
    </section>
  );
};

export const ListWithImageBlockFragment = /* GraphQL */ `
  fragment ListWithImageBlockFragment on ListWithImage {
    id: _id
    type: __typename
    title
    titleEmphasis: title_emphasis
    image {
      ${ImageAssetFragment(760, 460)}
      id: _id
    }
    imagePosition: image_position
    listType: list_type
    ctaLabel: cta_label
    ctaUrl: cta_url
    firstItemTitle: first_item_title
    firstItemBody: first_item_body
    secondItemTitle: second_item_title
    secondItemBody: second_item_body
    thirdItemTitle: third_item_title
    thirdItemBody: third_item_body
    fourthItemTitle: fourth_item_title
    fourthItemBody: fourth_item_body
    fifthItemTitle: fifth_item_title
    fifthItemBody: fifth_item_body
    
  }`;

export const ListWithImageBlockType = {
  title: string,
  titleEmphasis: string,
  image: arrayOf(shape(ImageType)),
  listType: oneOf(["check", "number"]),
  imagePosition: oneOf(["left", "right"]),
  ctaLabel: string,
  ctaUrl: string,
  firstItemTitle: string,
  firstItemBody: string,
  secondItemTitle: string,
  secondItemBody: string,
  thirdItemTitle: string,
  thirdItemBody: string,
  fourthItemTitle: string,
  fourthItemBody: string,
  fifthItemTitle: string,
  fifthItemBody: string,
};

ListWithImageBlock.propTypes = ListWithImageBlockType;

export default ListWithImageBlock;
