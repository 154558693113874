import zipcodes from "./zipcodes";

/**
 * @param {Number || String} zipcode
 * @return {[string, number[]]}
 */
const getNeighborhood = (zipcode) => {
  let _zipcode = zipcode;

  if (typeof zipcode === "string") {
    _zipcode = parseInt(zipcode.replace(/\D/g, ""));
  }

  return Object.entries(zipcodes).reduce(
    (acc, [key, value]) => (value.includes(_zipcode) ? acc.concat([key]) : acc),
    [],
  );
};

export default getNeighborhood;
