import { getCookie } from "cookies-next";
import { useTranslation } from "next-i18next";
import { arrayOf, elementType, oneOfType, shape, string } from "prop-types";
import { useEffect, useState } from "react";

import FeaturedBlock, { FeaturedBlockType } from "~components/FeaturedBlock";
import SpinningLoader from "~components/SpinningLoader";
import { PREPR_CUSTOMER_COOKIE_NAME } from "~config/prepr";
import { logError } from "~lib/logging";
import DynamicEventsAndLocationsBlock, {
  DynamicEventsAndLocationsBlockType,
} from "~modules/DynamicEventsAndLocationsBlock";

/**
 * Checks if the user has personalized data
 * @param {string} preprUid
 * @param {string} personalizationId
 * @param {string} locale
 * @return {Promise<Response>}
 */
const checkPersonalization = async (preprUid, personalizationId, locale) => {
  const searchParams = new URLSearchParams({
    preprUid,
    locale,
  });
  return fetch(
    `/api/personalization/check/${personalizationId}?${searchParams.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

/**
 * Gets the personalized content
 * @param {string} preprUid
 * @param {string} personalizationId
 * @param {string} locale
 * @return {Promise<Response>}
 */
const getPersonalization = async (preprUid, personalizationId, locale) => {
  const searchParams = new URLSearchParams({
    preprUid,
    locale,
  });
  return fetch(
    `/api/personalization/content/${personalizationId}?${searchParams.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  ).then(async (response) => {
    const result = await response.json();
    return result.body.content[0];
  });
};

/**
 * @param {import("next-i18next").TFunction} t
 * @param {{ type: "FeaturedBlock"|"UpcomingEventsBlock" }} data
 * @returns {JSX.Element|null}
 */
const getContentComponent = (t, data) => {
  switch (data.type) {
    case "FeaturedBlock":
      return (
        <FeaturedBlock
          {...data}
          overline={t("yourRecommendationsDescription")}
        />
      );
    case "UpcomingEventsBlock":
      return (
        <DynamicEventsAndLocationsBlock
          {...data}
          subTitle={t("yourRecommendationsDescription")}
        />
      );
    default:
      console.error(
        `RecommendationsBlock: Unexpected component type: ${data.type}`,
      );
      return null;
  }
};

const personalizationGuard = (Component) => {
  const WrappedComponent = ({ personalization, ...props }) => {
    if (!personalization?.length) {
      return null;
    }
    return <Component personalization={personalization} {...props} />;
  };

  WrappedComponent.displayName = `WithPersonalizationGuard(${
    Component.displayName || Component.name || "Component"
  })`;

  WrappedComponent.propTypes = {
    personalization: RecommendationsBlockType.personalization,
  };

  return WrappedComponent;
};

const RecommendationsBlock = ({ personalization = [], Container }) => {
  const { content, id } = personalization[0];
  const {
    t,
    i18n: { language: locale },
  } = useTranslation();
  const [data, setData] = useState(content[0]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const preprUid = getCookie(PREPR_CUSTOMER_COOKIE_NAME);
    if (!locale || !preprUid) {
      return;
    }
    const handleGetPersonalization = async () => {
      try {
        const response = await checkPersonalization(preprUid, id, locale);
        if (response.status === 200) {
          setLoading(true);
          const result = await getPersonalization(preprUid, id, locale);
          setData(result);
        }
      } catch (error) {
        logError(error);
      } finally {
        setLoading(false);
      }
    };

    handleGetPersonalization();

    document.addEventListener(
      "refreshPersonalisation",
      handleGetPersonalization,
    );

    return () => {
      document.removeEventListener(
        "refreshPersonalisation",
        handleGetPersonalization,
      );
    };
  }, [id, locale]);

  if (loading) {
    return <SpinningLoader size="small" />;
  }

  const contentComponent = data?.type ? getContentComponent(t, data) : null;

  if (!contentComponent) {
    return null;
  }

  return (
    <div
      className="GaRecommendationBlock"
      // eslint-disable-next-line
      prepr-personalisation={id}
      // eslint-disable-next-line
      prepr-segment={data.context?.segments?.filter(Boolean).join(", ")}>
      {Container ? <Container>{contentComponent}</Container> : contentComponent}
    </div>
  );
};

RecommendationsBlock.displayName = "RecommendationsBlock";

export const RecommendationsBlockType = {
  id: string,
  type: string,
  personalization: arrayOf(
    shape({
      id: string,
      title: string,
      titleEmphasis: string,
      content: arrayOf(
        oneOfType([
          shape(FeaturedBlockType),
          shape(DynamicEventsAndLocationsBlockType),
        ]),
      ),
    }),
  ),
  Container: elementType,
};

RecommendationsBlock.propTypes = RecommendationsBlockType;

export const RecommendationsBlockFragment = /* GraphQL */ `
  fragment RecommendationsBlockFragment on RecommendationsBlock {
    id: _id
    type: __typename
    personalization {
      id: _id
      title
      titleEmphasis: title_emphasis
      content {
        ... on FeaturedBlock {
          context: _context {
            segments
          }
        }
        ... on UpcomingEventsBlock {
          context: _context {
            segments
          }
        }
      }
    }
  }
`;

export default personalizationGuard(RecommendationsBlock);
