import { useTranslation } from "next-i18next";
import { func, number, string } from "prop-types";
import { useState } from "react";

import QuantityPicker from "~components/QuantityPicker";

import classes from "./ProductCard.module.scss";

const ProductCard = ({
  title,
  subtitle,
  beforePrice = 0,
  price,
  defaultQuantity = 0,
  onQuantityChange,
}) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(defaultQuantity);
  const onQuantityChangeHandler = (newQuantity) => {
    onQuantityChange && onQuantityChange(newQuantity);
    setQuantity(newQuantity);
  };

  return (
    <div data-testid="product-card" className={classes.card}>
      <div className={classes.cardHeader}>
        <h3>{title}</h3>
        <p className={classes.subtitle}>{subtitle}</p>
      </div>
      <div className={classes.cardFooter}>
        <div className={classes.cardPricing}>
          {Number(beforePrice) > 0 && (
            <span className={classes.cardPriceBefore}>€ {beforePrice}</span>
          )}
          <span className={classes.cardPrice}>&euro; {price}</span>
        </div>
        <QuantityPicker
          value={quantity}
          onChange={onQuantityChangeHandler}
          addValueTitle={t("quantityPicker.defaultAddTitle")}
          removeValueTitle={t("quantityPicker.defaultRemoveTitle")}
        />
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  title: string.isRequired,
  subtitle: string,
  beforePrice: number,
  price: number.isRequired,
  onQuantityChange: func,
  defaultQuantity: number,
};

export default ProductCard;
