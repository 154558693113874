import { arrayOf, oneOf, shape, string } from "prop-types";
import React from "react";

import Card from "~components/Cards";
import ItemRow from "~components/ItemRow";
import ImageAssetFragment from "~fragments/ImageAssetFragment";
import { ImageType } from "~types";

import classes from "./TopTasksBlock.module.scss";

const TopTasksBlock = ({ title, topTasks }) => {
  const filteredTopTasks =
    topTasks?.filter(
      (task) => task.slug && (task.heroImage || task.heroImageUrl),
    ) || [];
  if (filteredTopTasks.length === 0) {
    return null;
  }
  return (
    <>
      {title && <h2 className={classes.title}>{title}</h2>}
      <ItemRow>
        {filteredTopTasks.map(
          ({ id, heroImage, heroImageUrl, slug, title }) => {
            let image = heroImage?.[0];
            if (!image && heroImageUrl) {
              image = {
                alt: "",
                src: heroImageUrl,
                objectFit: "cover",
              };
            }
            return (
              <Card
                key={id}
                id={id}
                slug={`/${slug}`}
                title={title}
                image={image}
                type="link"
              />
            );
          },
        )}
      </ItemRow>
    </>
  );
};

export const TopTasksBlockType = {
  id: string,
  type: oneOf(["TopTasks", "ArticleOverviewBlock", "FeaturedBlock"]),
  title: string,
  topTasks: arrayOf(
    shape({
      id: string,
      heroImageUrl: string,
      heroImage: arrayOf(shape(ImageType)),
      slug: string,
      title: string,
    }),
  ),
};

TopTasksBlock.propTypes = TopTasksBlockType;

export const TopTasksBlockFragment = /* GraphQL */ `
  fragment TopTasksBlockFragment on TopTasks {
    id: _id
    type: __typename
    title
    topTasks: top_tasks {
      ... on Article {
        id: _id
        slug: _slug
        title
        heroImageUrl: hero_image_url
        heroImage: hero_image {
          ${ImageAssetFragment(768, 436)}
        }
      }
      ... on OverviewPage {
        id: _id
        slug: _slug
        title
        heroImage: hero_image {
          ${ImageAssetFragment(768, 436)}
        }
      }
      ... on VenueFinderPage {
        id: _id
        slug: _slug
        title
        heroImage: hero_image {
          ${ImageAssetFragment(768, 436)}
        }
      }
      ... on Directory {
        id: _id
        slug: _slug
        title
        heroImage: hero_image {
          ${ImageAssetFragment(768, 436)}
        }
      }
      ... on ProductSelectorPage {
        id: _id
        slug: _slug
        title
        heroImage: hero_image {
          ${ImageAssetFragment(768, 436)}
        }
      }
    }
  }
`;

export default TopTasksBlock;
