import dynamic from "next/dynamic";
import { arrayOf, shape, string } from "prop-types";
import { memo } from "react";

import { Neighborhood } from "~modules/NeighbourhoodBlock/Neighbourhoods";
import classnames from "~utils/classnames";

import classes from "./NeighbourhoodMap.module.scss";

const neighborhoodMap = {
  AMSTERDAM_ZUID: dynamic(() => import("./Neighbourhoods/AmsterdamZuid"), {
    ssr: false,
  }),
  AMSTERDAM_WEST: dynamic(() => import("./Neighbourhoods/AmsterdamWest"), {
    ssr: false,
  }),
  AMSTERDAM_ZUID_OOST: dynamic(
    () => import("./Neighbourhoods/AmsterdamZuidOost"),
    {
      ssr: false,
    },
  ),
  AMSTERDAM_OOST: dynamic(() => import("./Neighbourhoods/AmsterdamOost"), {
    ssr: false,
  }),
  AMSTERDAM_NOORD: dynamic(() => import("./Neighbourhoods/AmsterdamNoord"), {
    ssr: false,
  }),
  AMSTERDAM_CENTRUM: dynamic(
    () => import("./Neighbourhoods/AmsterdamCentrum"),
    {
      ssr: false,
    },
  ),
  AMSTERDAM_NIEUW_WEST: dynamic(
    () => import("./Neighbourhoods/AmsterdamNieuwWest"),
    {
      ssr: false,
    },
  ),
  WEESP: dynamic(() => import("./Neighbourhoods/Weesp"), { ssr: false }),
};

const Map = ({ links }) => {
  return (
    <svg viewBox="0 0 1244 764" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14735_232327)">
        <g opacity="0.2">
          <mask
            id="path-1-outside-1_14735_232327"
            maskUnits="userSpaceOnUse"
            x="99"
            y="19.0547"
            width="447"
            height="221"
            fill="black"
          >
            <rect fill="white" x="99" y="19.0547" width="447" height="221" />
            <path d="M132.683 22.0547L102 163.278L187.414 183.099V220.378H366.054V227.695H402.575V237.606H442.62V223.178H484.291V211.979V192.398L491.754 193.009L501.706 203.745L520.779 194.661H534.876L542.754 186.815L534.876 159.149L530.73 162.452L534.876 178.969H525.754L521.608 168.233L492.584 189.706L471.852 175.666L475.169 171.537L484.291 178.969L489.267 174.84L470.194 158.323L479.315 149.238L501.706 166.581L530.73 144.283L510.827 120.333L507.925 123.223L517.462 135.198V144.283L510.413 137.263L497.559 150.064L493.413 145.935L505.023 134.373L499.715 129.087L487.276 141.475L482.633 136.85L505.023 114.552L492.584 102.164H481.803V112.074L487.608 106.293L491.34 110.009L480.145 121.159H473.511L467.706 115.378L475.169 95.5568H445.316L451.95 125.288L463.145 136.437L457.755 141.805L439.096 123.223L431.633 130.656L429.145 128.179L439.511 117.855V104.641L436.608 101.751L410.901 127.353L407.584 124.049L425.413 106.293L393.072 100.512V94.7309L427.901 101.338L437.023 92.2533L355.755 74.0843L374.828 135.198H409.657L417.95 143.457H378.974V155.845H417.121V162.452H379.804V169.059H417.121V177.318H380.633V187.228H398.877V192.183H383.121V205.397H378.145V193.009H366.535V205.397H362.389V193.009H341.658V180.621H369.023V153.367L344.975 83.9947H315.121L307.658 69.9549H302.682V60.0446L231.365 46.0048L252.926 110.422H296.048V116.203H310.975V123.636H252.097V149.238H296.048V156.671H252.097V164.93H242.975L228.878 215.307H212.292L216.024 211.591H223.902L237.17 164.104H231.365V105.467L227.219 98.8603H211.463V93.0792H228.878L212.292 41.0496L163.366 30.3134L174.975 107.119H160.049L145.951 26.184L132.683 22.0547Z" />
          </mask>
          <path
            d="M132.683 22.0547L102 163.278L187.414 183.099V220.378H366.054V227.695H402.575V237.606H442.62V223.178H484.291V211.979V192.398L491.754 193.009L501.706 203.745L520.779 194.661H534.876L542.754 186.815L534.876 159.149L530.73 162.452L534.876 178.969H525.754L521.608 168.233L492.584 189.706L471.852 175.666L475.169 171.537L484.291 178.969L489.267 174.84L470.194 158.323L479.315 149.238L501.706 166.581L530.73 144.283L510.827 120.333L507.925 123.223L517.462 135.198V144.283L510.413 137.263L497.559 150.064L493.413 145.935L505.023 134.373L499.715 129.087L487.276 141.475L482.633 136.85L505.023 114.552L492.584 102.164H481.803V112.074L487.608 106.293L491.34 110.009L480.145 121.159H473.511L467.706 115.378L475.169 95.5568H445.316L451.95 125.288L463.145 136.437L457.755 141.805L439.096 123.223L431.633 130.656L429.145 128.179L439.511 117.855V104.641L436.608 101.751L410.901 127.353L407.584 124.049L425.413 106.293L393.072 100.512V94.7309L427.901 101.338L437.023 92.2533L355.755 74.0843L374.828 135.198H409.657L417.95 143.457H378.974V155.845H417.121V162.452H379.804V169.059H417.121V177.318H380.633V187.228H398.877V192.183H383.121V205.397H378.145V193.009H366.535V205.397H362.389V193.009H341.658V180.621H369.023V153.367L344.975 83.9947H315.121L307.658 69.9549H302.682V60.0446L231.365 46.0048L252.926 110.422H296.048V116.203H310.975V123.636H252.097V149.238H296.048V156.671H252.097V164.93H242.975L228.878 215.307H212.292L216.024 211.591H223.902L237.17 164.104H231.365V105.467L227.219 98.8603H211.463V93.0792H228.878L212.292 41.0496L163.366 30.3134L174.975 107.119H160.049L145.951 26.184L132.683 22.0547Z"
            stroke="black"
            strokeWidth="4"
            strokeMiterlimit="10"
            mask="url(#path-1-outside-1_14735_232327)"
          />
        </g>
        {links.map((link, index) => {
          const NeighborhoodComponent = neighborhoodMap[link.neighbourhood];
          return (
            <Neighborhood
              key={index}
              neighborhood={link.neighbourhood}
              links={links}
            >
              <NeighborhoodComponent
                className={classnames(
                  classes[link.neighbourhood],
                  classes.hoverablePath,
                )}
              />
            </Neighborhood>
          );
        })}
        <g clipPath="url(#clip1_14735_232327)">
          <path
            opacity="0.2"
            d="M109.947 1.17383L414.656 72.2853L398.329 27.6872L365.257 12.5557L398.329 13.6341"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.2"
            d="M699.515 -7.94531L665.84 32.8866L652 72.2916"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.2"
            d="M1200 502.055L1121.29 457.037L1091.59 474.217L1053.21 465.631L1017.49 482.928L979.448 495.371L962.426 470.277L993.631 445.182L962.426 418.826"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.2"
            d="M627.323 561.736L605.554 583.928L624.409 620.636L585.459 644.661L592.157 662.945L565.365 674.559L538.572 733.459L530.199 750.051"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.2"
            d="M186.918 762.496L262.298 699.473L279.018 646.355L335.475 605.705L349.349 540.998"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            opacity="0.2"
            d="M-1.5 236.127H109.947L155.101 249.74"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_14735_232327"
          x1="648.438"
          y1="540.83"
          x2="461.385"
          y2="280.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62129" />
          <stop offset="1" stopColor="#0709EC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14735_232327"
          x1="562.512"
          y1="376.83"
          x2="374.142"
          y2="237.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FA" />
          <stop offset="1" stopColor="#9BFE60" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14735_232327"
          x1="936.503"
          y1="748.337"
          x2="683.569"
          y2="503.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FA" />
          <stop offset="1" stopColor="#9BFE60" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_14735_232327"
          x1="944.539"
          y1="634.367"
          x2="861.445"
          y2="594.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000FA" />
          <stop offset="1" stopColor="#9BFE60" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_14735_232327"
          x1="1240.24"
          y1="749.335"
          x2="988.14"
          y2="465.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9D18" />
          <stop offset="1" stopColor="#E8091F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_14735_232327"
          x1="171"
          y1="220.055"
          x2="516.556"
          y2="326.356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_14735_232327"
          x1="1098.63"
          y1="302.371"
          x2="867.506"
          y2="-99.9101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62129" />
          <stop offset="1" stopColor="#0709EC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_14735_232327"
          x1="694.986"
          y1="258.14"
          x2="546.456"
          y2="180.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62129" />
          <stop offset="1" stopColor="#0709EC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_14735_232327"
          x1="628.147"
          y1="250.68"
          x2="577.747"
          y2="229.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62129" />
          <stop offset="1" stopColor="#0709EC" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_14735_232327"
          x1="647.926"
          y1="176.84"
          x2="489.856"
          y2="14.4605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E62129" />
          <stop offset="1" stopColor="#0709EC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_14735_232327"
          x1="703.568"
          y1="369.492"
          x2="543.972"
          y2="200.883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9D18" />
          <stop offset="1" stopColor="#E8091F" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_14735_232327"
          x1="591"
          y1="456.721"
          x2="670.056"
          y2="471.646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_14735_232327"
          x1="617.535"
          y1="330.949"
          x2="773.253"
          y2="418.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_14735_232327"
          x1="636.426"
          y1="369.352"
          x2="813.181"
          y2="460.232"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_14735_232327"
          x1="636.211"
          y1="444.92"
          x2="728.563"
          y2="501.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_14735_232327"
          x1="768.969"
          y1="264.055"
          x2="851.426"
          y2="287.858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_14735_232327"
          x1="810.547"
          y1="374.82"
          x2="916.62"
          y2="420.579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_14735_232327"
          x1="862.586"
          y1="354.9"
          x2="958.952"
          y2="384.934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB9C18" />
          <stop offset="1" stopColor="#C8EF52" />
        </linearGradient>
        <clipPath id="clip0_14735_232327">
          <rect
            width="1244"
            height="763"
            fill="white"
            transform="translate(0 0.0546875)"
          />
        </clipPath>
        <clipPath id="clip1_14735_232327">
          <rect
            width="1200"
            height="763"
            fill="white"
            transform="translate(0 0.0546875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NeighbourhoodMapTypes = {
  links: arrayOf(
    shape({
      label: string.isRequired,
      link: string.isRequired,
      neighbourhood: string.isRequired,
    }),
  ).isRequired,
};

Map.propTypes = NeighbourhoodMapTypes;

export default memo(Map);
