import { object, shape, string } from "prop-types";
import { useCallback, useEffect, useState } from "react";

import classnames from "~utils/classnames";

import classes from "./HorizontalScrollbar.module.scss";

const HorizontalScrollbar = ({ containerRef, barClassname }) => {
  const [barWidth, setBarWidth] = useState(0);
  const [barPosition, setBarPosition] = useState(0);

  const updateBarWidth = useCallback(() => {
    const containerElement = containerRef.current;

    if (!containerElement) {
      return;
    }

    const containerWidth = containerElement.getBoundingClientRect().width;
    const scrollWidth = containerElement.scrollWidth;
    setBarWidth(containerWidth * (containerWidth / scrollWidth));
  }, [containerRef]);

  const updateBarPosition = useCallback(() => {
    const containerElement = containerRef.current;

    if (!containerElement) {
      return;
    }

    const scrollWidth = containerElement.scrollWidth;

    if (scrollWidth === 0) {
      return;
    }

    const containerWidth = containerElement.getBoundingClientRect().width;
    const scale = Math.min(1, containerWidth / scrollWidth);
    setBarPosition(containerElement.scrollLeft * scale);
  }, [containerRef]);

  useEffect(() => {
    const containerElement = containerRef.current;
    updateBarWidth();

    const onScroll = () => {
      updateBarPosition();
    };

    containerElement.addEventListener("scroll", onScroll);

    return () => {
      containerElement.removeEventListener("scroll", onScroll);
    };
  }, [containerRef, updateBarPosition, updateBarWidth]);

  useEffect(() => {
    const containerElement = containerRef.current;
    const resizeObserver = new ResizeObserver(() => {
      updateBarWidth();
    });
    resizeObserver.observe(containerElement);

    return () => resizeObserver.disconnect();
  }, [containerRef, updateBarWidth]);

  useEffect(() => {
    const containerElement = containerRef.current;
    const mutationObserver = new MutationObserver(() => {
      updateBarWidth();
    });

    mutationObserver.observe(containerElement, {
      childList: true,
      subtree: true,
    });

    return () => mutationObserver.disconnect();
  }, [containerRef, updateBarWidth]);

  return (
    <div className={classnames(classes.bar, barClassname)}>
      <div
        className={classes.progressBar}
        style={{ width: `${barWidth}px`, left: `${barPosition}px` }}
      />
    </div>
  );
};

HorizontalScrollbar.propTypes = {
  containerRef: shape({
    current: object.isRequired,
  }).isRequired,
  barClassname: string,
};

export default HorizontalScrollbar;
