import { arrayOf, shape, string } from "prop-types";
import React from "react";

import Card from "~components/Cards";
import DuoColorText from "~components/DuoColorText";
import { PersonType } from "~components/PersonCard";
import ImageAssetFragment from "~fragments/ImageAssetFragment";
import classnames from "~utils/classnames";

import classes from "./PersonsBlock.module.scss";

const PersonsBlock = ({ title, titleEmphasis, persons }) => {
  const gridColumns = Math.max(2, Math.min(persons.length, 4));
  const gridClass = classnames(classes.grid, classes[`cols_${gridColumns}`]);
  return (
    <section>
      {title && (
        <h3 className={classes.title}>
          <DuoColorText text={title} emphasis={titleEmphasis} />
        </h3>
      )}
      <div className={gridClass}>
        {persons.map((item) => (
          <Card key={item.id} vertical={gridColumns <= 2} {...item} />
        ))}
      </div>
    </section>
  );
};

export const PersonsBlockType = {
  title: string,
  titleEmphasis: string,
  persons: arrayOf(shape(PersonType)),
};

export const PersonsBlockFragment = /* GraphQL */ `
  fragment PersonsBlockFragment on PersonsBlock {
    type: __typename
    title
    titleEmphasis: title_emphasis
    persons {
      ... on Author {
        id: _id
        type: __typename
        name: full_name
        firstName: first_name
        description
        socials {
          ... on SocialMedia {
            id: _id
            platform
            url
          }
        }
        avatar {
          ${ImageAssetFragment(576, 292)}
        }
      }
      ... on Acquisitor {
        id: _id
        type: __typename
        name
        jobTitle: job_title
        email
        contactPage: contact_page {
          slug: _slug
        }
        phoneNumber: phone_number
        avatar {
          ${ImageAssetFragment(576, 292)}
        }
      }
    }
  }`;

PersonsBlock.propTypes = PersonsBlockType;

export default PersonsBlock;
