import { useEffect, useMemo, useRef, useState } from "react";

import cssVariables from "~styles/export.module.scss";

/**
 * Custom hook to get the current breakpoint and utility functions.
 *
 * @param {import("./hookTypes").Breakpoints} [customBreakpoints] - Custom breakpoints to override the default breakpoints.
 * @returns {import("./hookTypes").UseBreakpointReturn} - An object containing the current breakpoint and utility functions.
 *
 * @example
 * const { currentBreakpoint, isXS, isSM, isMD, isLG, isXL, isUpToXS, isUpToSM, isUpToMD, isUpToLG, isUpToXL } = useBreakpoint();
 */

const useBreakpoint = (customBreakpoints) => {
  const widthRef = useRef(0);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("xs");

  /** @type {import("./hookTypes").Breakpoints} */
  const breakpoints = useMemo(() => {
    return (
      customBreakpoints ||
      Object.keys(cssVariables).reduce((acc, key) => {
        if (key.startsWith("breakpoint")) {
          const shortKey = key.replace("breakpoint", "").toLowerCase(); // Converts 'breakpointXS' to 'xs'
          acc[shortKey] = parseInt(cssVariables[key], 10);
        }
        return acc;
      }, {})
    );
  }, [customBreakpoints]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== widthRef.current) {
        widthRef.current = newWidth;
        const matchedBreakpoint = Object.keys(breakpoints).reduce(
          (acc, key) => {
            return newWidth >= breakpoints[key] ? key : acc;
          },
          "xs",
        );
        setCurrentBreakpoint(matchedBreakpoint);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoints]);

  /** @type {import("./hookTypes").BreakpointChecks} */
  const breakpointChecks = useMemo(() => {
    return Object.keys(breakpoints).reduce((acc, breakpoint) => {
      acc[`is${breakpoint.toUpperCase()}`] = () =>
        currentBreakpoint === breakpoint;
      acc[`isUpTo${breakpoint.toUpperCase()}`] = () =>
        widthRef.current <= breakpoints[breakpoint];
      return acc;
    }, {});
  }, [breakpoints, currentBreakpoint]);

  // @ts-ignore
  return { currentBreakpoint, ...breakpointChecks };
};

export default useBreakpoint;
