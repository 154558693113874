const zipcodes = {
  nieuwwest: [
    1067, 1063, 1064, 1065, 1066, 1060, 1069, 1068, 1046, 1043, 1042, 1062,
    1067, 1065, 1068, 1069, 1062, 1067, 1063, 1064, 1043, 1046, 1042, 1060,
    1066,
  ],
  noord: [
    1021, 1022, 1023, 1024, 1025, 1026, 1027, 1028, 1031, 1032, 1033, 1034,
    1035, 1036, 1037, 1033, 1031, 10361021, 1022, 1023, 1025, 1031, 1032,
    10341024, 1027, 1028, 1026, 1033, 1037, 1035,
  ],
  oost: [
    1019, 1086, 1087, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098, 1019,
    1093, 1094, 10951091, 1092, 1096, 1097, 1098, 1086, 1087, 1095,
  ],
  zuidoost: [
    1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108, 1114, 1102, 1114, 1101,
    1105, 1106, 1107, 1108, 1103, 1104,
  ],
  west: [
    1013, 1014, 1047, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058, 1061,
    1055, 1056, 1061, 1057, 1058, 1053, 1054, 1013, 1051, 1052, 1014, 1047,
  ],
  zuid: [
    1058, 1059, 1071, 1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1081,
    1082, 1083, 1072, 1073, 1074, 1071, 1075, 1077, 1078, 1079, 1076, 1081,
    1082, 1083, 1058, 1059,
  ],
  centrum: [
    1011, 1012, 1013, 1015, 1016, 1017, 1018, 1015, 1016, 1012, 1013, 1015,
    1011, 1018, 1015, 1016, 1017, 1017, 1017, 1109, 1381, 1382, 1383, 1384,
  ],
  weesp: [1109, 1381, 1382, 1383, 1384],
};

export default zipcodes;
