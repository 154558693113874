import { useTranslation } from "next-i18next";
import { arrayOf, shape, string } from "prop-types";
import { useRef } from "react";

import Logo from "~components/750/logo";
import Button from "~components/Button";
import { ExpertCard, ExpertCardType } from "~components/Cards/ExpertCard";
import Carousel from "~components/Carousel";
import IconButton from "~components/IconButton";
import { useTheme } from "~contexts/ThemeContext";
import ImageAssetFragment from "~fragments/ImageAssetFragment";
import BannerCross from "~public/images/750/banners/bannerCross.svg";
import BannerGradient from "~public/images/750/banners/bannerGradient.svg";
import classnames from "~utils/classnames";

import classes from "./ExpertCarousel.module.scss";

const CONFIG_CAROUSEL = {
  900: {
    slidesPerView: 2,
    width: null,
    centeredSlides: true,
  },
  1200: {
    slidesPerView: 3,
    width: null,
  },
};

const ExpertCarousel = ({ title, description, experts = [], link, label }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <section className={classes.iconSlider}>
      <Logo className={classes.logo} />
      <div className={classes.content}>
        {title && <h2 className={classes.title}>{title}</h2>}
        {description && <p className={classes.description}>{description}</p>}
      </div>
      <div className={classes.carousel}>
        {experts.length > 0 && (
          <Carousel
            showNavigation
            prevButtonRef={prevRef}
            nextButtonRef={nextRef}
            width={300}
            breakpoints={CONFIG_CAROUSEL}
            className={classes.slider}
          >
            {experts.map((expert) => (
              <ExpertCard key={expert.id} {...expert} />
            ))}
          </Carousel>
        )}
        <div className={classes.background}>
          <BannerCross />
          <BannerGradient />
        </div>
      </div>
      <div className={classes.controls}>
        <IconButton
          icon="arrowLeftThin"
          title={t("previous")}
          ref={prevRef}
          className={classnames(classes.prevButton)}
        />
        <IconButton
          icon="arrowRightThin"
          title={t("next")}
          ref={nextRef}
          className={classnames(classes.nextButton)}
        />
      </div>
      {link?.[0]?.slug && label && (
        <Button
          color="secondary"
          href={link[0].slug}
          className={classes.cta}
          hasTheme={theme === "750"}
        >
          {label}
        </Button>
      )}
    </section>
  );
};

ExpertCarousel.propTypes = {
  title: string,
  description: string,
  experts: arrayOf(shape(ExpertCardType)),
  link: arrayOf(shape({ slug: string })),
  label: string,
};

export const IconSliderFragment = /* GraphQL */ `
  fragment IconSliderFragment on IconSlider {
    id: _id
    type: __typename
    title
    description
    link {
      ... on OverviewPage {
        slug: _slug
      }
    }
    label: link_label
    experts {
      ... on ExpertDetail {
        id: _id
        type: __typename
        title: hero_title
        description: hero_description
        image: hero_image {
          ${ImageAssetFragment(531, 531)}
        }
        slug: _slug
      }
    }
  }
`;

export default ExpertCarousel;
