import { bool, func, node, oneOfType, string } from "prop-types";
import { forwardRef } from "react";

import CheckmarkIcon from "~public/icons/check.svg";
import classnames from "~utils/classnames";

import FormInputErrorMessage from "../FormInputErrorMessage";
import classes from "./Checkbox.module.scss";

/**
 * @typedef {Object} CheckboxProps
 * @property {string} id
 * @property {boolean} [checked]
 * @property {boolean} [defaultChecked]
 * @property {string | React.ReactNode} label
 * @property {boolean} [hasError]
 * @property {string} [errorMessage]
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} [onChange]
 * @property {(event: React.FocusEvent<HTMLInputElement>) => void} [onBlur]
 * @property {string} name
 * @property {boolean} [required]
 * @property {string} [className]
 */

/**
 * Checkbox Component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<CheckboxProps> & React.RefAttributes<HTMLInputElement>>}
 * @example
 * <Checkbox id="checkbox" label="Check me" name="checkbox" />
 */

const Checkbox = forwardRef(
  (
    {
      id,
      checked,
      defaultChecked,
      label,
      hasError = false,
      errorMessage,
      onChange,
      onBlur,
      name,
      required = false,
      className,
    },
    ref,
  ) => {
    const props = {};
    if (typeof checked === "boolean") {
      props.checked = checked;
    } else if (typeof defaultChecked === "boolean") {
      props.defaultChecked = defaultChecked;
    }
    return (
      <div
        className={classnames(
          className,
          classes.Checkbox,
          hasError && classes.hasError,
        )}
      >
        <label>
          <input
            ref={ref}
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            type="checkbox"
            aria-invalid={hasError ? "true" : "false"}
            aria-describedby={hasError ? `${id}-error` : null}
            {...props}
          />
          <div className={classes.Checkbox_checkmarkIcon}>
            <CheckmarkIcon width="14" />
          </div>
          <span>{label}</span>
        </label>
        {hasError && errorMessage && (
          <FormInputErrorMessage htmlFor={id}>
            {errorMessage}
          </FormInputErrorMessage>
        )}
      </div>
    );
  },
);

export const checkboxPropTypes = {
  id: string.isRequired,
  checked: bool,
  defaultChecked: bool,
  label: oneOfType([string, node]).isRequired,
  hasError: bool,
  errorMessage: string,
  onChange: func,
  onBlur: func,
  name: string.isRequired,
  required: bool,
  className: string,
};

Checkbox.propTypes = checkboxPropTypes;

Checkbox.displayName = "Checkbox";

export default Checkbox;
