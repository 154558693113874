import sentryConfig from "~config/sentry";

function getConsoleLogger(level) {
  switch (level) {
    case "info":
      return console.log;
    case "error":
      return console.error;
    default:
      return console.log;
  }
}

let Sentry;
/**
 *
 * @param {"info"|"error"} level
 * @param {Error|string} messageOrError
 * @param {string} [context=null]
 * @param {{[key: string]: any}} [extra={}]
 * @param {{[key: string]: any}} [tags={}]
 * @returns {Promise<void>}
 */
async function log(
  level,
  messageOrError,
  context = null,
  extra = {},
  tags = {},
) {
  const consoleLogger = getConsoleLogger(level);
  consoleLogger(messageOrError, {
    level,
    context,
    extra,
    tags,
  });
  if (typeof window === "undefined") {
    // is server, log to axiom
    const { writeLog } = await import("~lib/logging/axiom");
    await writeLog({
      level,
      message: messageOrError.toString(),
      context,
      extra,
      tags,
    });
    return;
  }
  if (!sentryConfig.dsn) {
    return;
  }
  // is browser, log to sentry
  if (!Sentry) {
    Sentry = await import("@sentry/browser");
    Sentry.init(sentryConfig);
  }
  if (level === "error") {
    Sentry.captureException(messageOrError, {
      level: "error",
      extra,
      tags: {
        context,
        ...tags,
      },
    });
  } else {
    Sentry.captureMessage(messageOrError, {
      level,
      extra,
      tags: {
        context,
        ...tags,
      },
    });
  }
}

/**
 *
 * @param {string} message
 * @param {string} [context=null]
 * @param {{[key: string]: any}} [extra={}]
 * @param {{[key: string]: any}} [tags={}]
 */
export async function logInfo(message, context = null, extra = {}, tags = {}) {
  return log("info", message, context, extra, tags);
}

/**
 *
 * @param {Error|string} error
 * @param {string} [context=null]
 * @param {{[key: string]: any}} [extra={}]
 * @param {{[key: string]: any}} [tags={}]
 * @returns {Promise<void>}
 */
export async function logError(error, context = null, extra = {}, tags = {}) {
  return log("error", error, context, extra, tags);
}

/**
 * @param {string} context
 */
export const contextInfoLogger =
  (context) =>
  (message, extra = {}, tags = {}) =>
    logInfo(message, context, extra, tags);

/**
 * @param {string} context
 */
export const contextErrorLogger =
  (context) =>
  (error, extra = {}, tags = {}) =>
    logError(error, context, extra, tags);
