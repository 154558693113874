import { arrayOf, oneOf, shape, string } from "prop-types";

import Button from "~components/Button";
import Card from "~components/Cards";
import ImageAssetFragment from "~fragments/ImageAssetFragment";
import { AuthorType, ImageType } from "~types";
import formatDate from "~utils/formatDate";

import classes from "./ArticleOverviewBlock.module.scss";

const ArticleOverviewBlock = ({
  title,
  ctaLabel,
  ctaSlug,
  articleItems,
  locale,
}) => (
  <section className={classes.overview}>
    <h2 className={classes.header_3}>{title}</h2>
    {ctaLabel && ctaSlug && (
      <Button
        className={classes.cta}
        size="small"
        variant="outline"
        href={`/${ctaSlug}`}
      >
        {ctaLabel}
      </Button>
    )}
    <div className={classes.items}>
      {articleItems.map((item) => {
        let image = item.image?.[0];
        if (!image && item.imageUrl) {
          image = {
            alt: "",
            src: item.imageUrl,
            objectFit: "cover",
          };
        }
        return (
          <div key={item.id} className={classes.item}>
            <Card
              type="link"
              size="small"
              slug={`/${item.slug}`}
              image={image}
              title={item.title}
              top={
                (item.changedOn || item.publishedOn) &&
                (item.changedOn
                  ? formatDate(item.changedOn, locale, "dd MMMM yyyy")
                  : formatDate(item.publishedOn, locale, "dd MMMM yyyy"))
              }
              bottom={item.author?.[0]?.fullName}
            />
          </div>
        );
      })}
    </div>
  </section>
);

export const ArticleOverviewBlockType = {
  locale: string.isRequired,
  id: string,
  type: oneOf(["TopTasks", "ArticleOverviewBlock", "FeaturedBlock"]),
  title: string,
  ctaLabel: string,
  ctaSlug: string,
  articleItems: arrayOf(
    shape({
      id: string,
      type: oneOf(["Article", "NewsArticle", "OverviewPage"]),
      slug: string,
      title: string,
      image: arrayOf(shape(ImageType)),
      author: arrayOf(shape(AuthorType)),
    }),
  ),
};

ArticleOverviewBlock.propTypes = ArticleOverviewBlockType;

export const ArticleOverviewFragment = /* GraphQL */ `
  fragment ArticleOverviewFragment on ArticleOverviewBlock {
    id: _id
    type: __typename
    title
    ctaLabel: cta_label
    ctaSlug: cta_slug
    articleItems: items {
      ... on Article {
        id:_id
        type: __typename
        slug: _slug
        publishedOn: _publish_on
        changedOn: _changed_on
        title
        image: hero_image {
          ${ImageAssetFragment(200, 200)}
        }
        imageUrl: hero_image_url
        author {
          fullName: full_name
        }
      }
      ... on NewsArticle {
        id:_id
        type: __typename
        slug: _slug
        publishedOn: _publish_on
        title
        image: hero_image {
          ${ImageAssetFragment(200, 200)}
        }
        imageUrl: hero_image_url
        author {
          fullName: full_name
        }
      }
      ... on OverviewPage {
        id:_id
        type: __typename
        slug: _slug
        title
        image: hero_image {
          ${ImageAssetFragment(200, 200)}
        }
      }
    }
  }
`;

export default ArticleOverviewBlock;
