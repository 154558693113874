import { useTranslation } from "next-i18next";
import { func, number } from "prop-types";

import CircleButton from "~components/CircleButton";
import ArrowLeft from "~public/icons/arrow-left-light.svg";
import ArrowRight from "~public/icons/arrow-right-light.svg";
import { clamp } from "~utils/utils";

import classes from "./Pagination.module.scss";

const Pagination = ({
  currentPage: _currentPage,
  totalPages,
  pagesVisible = 5,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const currentPage = clamp(_currentPage, 1, totalPages);
  const pageLinks = Array.from(
    { length: Math.min(totalPages, pagesVisible) },
    (_, index) => index + 1,
  );

  const getPageNumber = (pageNumber) => {
    if (totalPages <= pagesVisible) {
      return pageNumber;
    }
    // Start of pagination
    if (currentPage < Math.ceil(pagesVisible / 2)) {
      return pageNumber;
      // End of pagination
    } else if (currentPage > totalPages - 2) {
      return totalPages - (pagesVisible - pageNumber);
    }
    return currentPage - Math.ceil(pagesVisible / 2) + pageNumber;
  };

  const handlePageChange = (event, pageNumber) => {
    if (
      pageNumber === currentPage ||
      pageNumber < 1 ||
      pageNumber > totalPages
    ) {
      return;
    }
    if (onPageChange) {
      event.preventDefault();
      onPageChange(pageNumber);
    }
  };

  return (
    <div className={classes.pagination}>
      {totalPages > 1 && (
        <a
          className={classes.pagination_link_prev}
          rel="prev"
          onClick={(e) => handlePageChange(e, currentPage - 1)}
        >
          <ArrowLeft />
          {t("previous")}
        </a>
      )}
      <div className={classes.pages}>
        {pageLinks.map((number) => (
          <CircleButton
            key={number.toString()}
            as="button"
            title={t("goToPageNumber", {
              pageNumber: getPageNumber(number),
            })}
            isActive={getPageNumber(number) === currentPage}
            onClick={(e) => handlePageChange(e, getPageNumber(number))}
          >
            {getPageNumber(number)}
          </CircleButton>
        ))}
      </div>
      {totalPages > 1 && (
        <a
          className={classes.pagination_link_next}
          rel="next"
          onClick={(e) => handlePageChange(e, currentPage + 1)}
        >
          {t("next")}
          <ArrowRight />
        </a>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: number.isRequired,
  totalPages: number.isRequired,
  pagesVisible: number,
  onPageChange: func.isRequired,
};

export default Pagination;
