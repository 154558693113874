import { useTranslation } from "next-i18next";
import { any, arrayOf, shape, string } from "prop-types";
import { useRef } from "react";

import Button from "~components/Button";
import DuoColorText from "~components/DuoColorText";
import HorizontalScrollbar from "~components/HorizontalScrollbar";
import ImageComponent from "~components/ImageComponent";

import classes from "./LogosBlock.module.scss";

const hasOverlay = (logo) => logo.subtitle || logo.url;

const LogosBlock = ({ title, titleEmphasis, description, logos }) => {
  const { t } = useTranslation();
  const containerRef = useRef();

  return (
    <section>
      <h2 className={classes.blockHeader}>
        <DuoColorText text={title} emphasis={titleEmphasis} />
      </h2>
      {description && <p className={classes.description}>{description}</p>}
      <div className={classes.grid} ref={containerRef}>
        {logos.map((logo) => (
          <div key={logo.id} className={classes.logo}>
            <ImageComponent {...logo.logoImage} hideAuthor />
            {hasOverlay(logo) && (
              <div className={classes.overlay}>
                {logo.subtitle && (
                  <div className={classes.overlayTop}>{logo.subtitle}</div>
                )}
                {logo.url && (
                  <div className={classes.overlayBottom}>
                    <Button
                      variant="outline"
                      size="small"
                      href={logo.url}
                      target="_blank"
                    >
                      {t("visitWebsite")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.scrollbarWrapper}>
        <HorizontalScrollbar containerRef={containerRef} />
      </div>
    </section>
  );
};

export const LogosBlockFragment = /* GraphQL*/ `
  fragment LogosBlockFragment on LogosBlock {
    type: __typename
    title
    titleEmphasis: title_emphasis
    description
    logos {
      ... on Logo {
        id:_id
        logoImage: logo_image {
          id: _id
          type: __typename
          src: url(width: 400,  format: "webp")
          width
          height
          alt: description
          author
        }
        subtitle
        url
      }
    }
  }
`;

export const LogosBlockType = {
  title: string.isRequired,
  titleEmphasis: string,
  description: string,
  logos: arrayOf(
    shape({
      logoImage: any,
      subtitle: string,
      url: string,
    }),
  ),
};

LogosBlock.propTypes = LogosBlockType;

export default LogosBlock;
