/**
 * @typedef {object} ApiResponse
 * @property {number} status
 * @property {any} [response]
 * @property {string} [error]
 */

/**
 *
 * @param {object} data
 * @returns {Promise<ApiResponse>}
 */
export async function registerCustomer(data) {
  const res = await fetch("/api/registration/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return res.json();
}

/**
 * @param {{
 *  Email: string
 *  Postcode: string
 *  LanguageCode: string
 *  captchaToken: string
 * }} data
 * @returns {Promise<ApiResponse>}
 */
export async function requestEmail(data) {
  const res = await fetch("/api/registration/resend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return res.json();
}

/**
 * @param {string} token
 * @returns {Promise<boolean>}
 */
export async function verifyCaptchaAction(token) {
  return fetch(`/api/reCaptcha?token=${token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => !!data?.verified);
}
