import { useRouter } from "next/router";
import Script from "next/script";
import { oneOf, string } from "prop-types";
import { useEffect, useRef } from "react";

import localeStringConverter from "~common/localeStringConverter";

import widgetTemplates from "./widgetTemplates.json";

const TrustPilotBlock = ({ templateName = "slider", stars = "1,2,3,4,5" }) => {
  const ref = useRef(null);
  const { locale: lang } = useRouter();
  const locale = localeStringConverter.toLocale(lang);
  const template = widgetTemplates[templateName];

  useEffect(() => {
    // @ts-ignore
    const trustpilot = window.Trustpilot;
    if (trustpilot) {
      trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if (!template) {
    return null;
  }

  return (
    <section>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale={locale}
        data-template-id={template.id}
        data-businessunit-id="55dad5f60000ff0005827ecb"
        data-style-height={template.height}
        data-style-width="100%"
        data-theme="light"
        data-stars={stars}
      >
        <a
          href="https://nl.trustpilot.com/review/iamsterdam.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </section>
  );
};

TrustPilotBlock.propTypes = {
  templateName: oneOf(Object.keys(widgetTemplates)).isRequired,
  stars: string,
};

export const TrustPilotBlockFragment = /* GraphQL */ `
  fragment TrustPilotBlockFragment on TrustPilotBlock {
    id: _id
    type: __typename
    templateName: widget_template
    stars
  }
`;

export default TrustPilotBlock;
