import { arrayOf, func, number, shape, string } from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";

import ProductSelectorBlock from "~components/ProductSelectorBlock";
import { pushEcommerceEvent } from "~lib/analytics/events";
import { addOrUpdateProduct, removeProduct } from "~lib/requests/shop/basket";

const handleProductUpdates = async (products) => {
  let lastResponse = null;
  for (const product of products) {
    let analyticsEvent = "add_to_cart";
    if (product.quantity > 0) {
      lastResponse = await addOrUpdateProduct(
        product.productId,
        product.quantity,
      );
    } else {
      analyticsEvent = "remove_from_cart";
      lastResponse = await removeProduct(product.productId);
    }
    if (lastResponse?.data?._gaItems) {
      pushEcommerceEvent(analyticsEvent, lastResponse.data._gaItems);
    }
  }
  return lastResponse?.data || null;
};

const ProductGroupBlock = ({
  anchorId,
  anchorOffsetY = 0,
  title,
  titleEmphasis,
  subtitle,
  products: _products,
  productGroup,
  onCheckout,
  totalLabel,
  checkoutLabel,
}) => {
  const [productRequestQueue, setProductRequestQueue] = useState(new Set());
  const [productQuantities, setProductQuantities] = useState({});

  const products = useMemo(
    () =>
      _products.map((product) => ({
        ...product,
        quantity: productQuantities[product.productId] ?? product.quantity ?? 0,
      })),
    [_products, productQuantities],
  );

  const updatedProducts = useMemo(
    () =>
      products.filter((product) => productRequestQueue.has(product.productId)),
    [productRequestQueue, products],
  );

  const totalPrice = useMemo(
    () =>
      products.reduce(
        (acc, product) => acc + product.quantity * product.price,
        0,
      ),
    [products],
  );

  const processRequestQueue = useCallback(async () => {
    if (updatedProducts.length > 0) {
      try {
        await handleProductUpdates(updatedProducts);
      } catch (error) {
        // TODO: show some error that the basket didn't update
        console.error(error);
        return;
      }
      setProductRequestQueue(new Set());
    }
  }, [updatedProducts]);

  useEffect(() => {
    if (updatedProducts.length === 0) {
      return;
    }
    const timeout = setTimeout(processRequestQueue, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [updatedProducts, processRequestQueue]);

  const onHandleCheckout = async () => {
    await processRequestQueue();
    onCheckout();
  };

  const onProductQuantityChange = (productId, quantity) => {
    setProductQuantities((state) => ({
      ...state,
      [productId]: quantity,
    }));
    setProductRequestQueue(
      (state) => new Set([...Array.from(state), productId]),
    );
  };

  return (
    <div>
      {anchorId && (
        <span
          id={anchorId}
          style={{
            position: "relative",
            visibility: "hidden",
            top: `${anchorOffsetY}px`,
          }}
        />
      )}
      <ProductSelectorBlock
        title={title}
        subtitle={subtitle}
        titleEmphasis={titleEmphasis}
        productGroupTitle={productGroup.title}
        products={products}
        totalPrice={totalPrice}
        onProductQuantityChange={onProductQuantityChange}
        onCheckout={onHandleCheckout}
        totalLabel={totalLabel}
        checkoutLabel={checkoutLabel}
      />
    </div>
  );
};

export const ProductGroupBlockFragment = /* GraphQL */ `
  fragment ProductGroupBlockFragment on ProductGroupBlock {
    id: _id
    type: __typename
    title
    titleEmphasis: title_emphasis
    subtitle
  }
`;

export const ProductGroupBlockType = {
  anchorId: string,
  anchorOffsetY: number,
  title: string,
  titleEmphasis: string,
  subtitle: string,
  products: arrayOf(
    shape({
      productId: string.isRequired,
      title: string.isRequired,
      subtitle: string,
      price: number.isRequired,
      quantity: number,
    }),
  ).isRequired,
  productGroup: shape({
    title: string.isRequired,
  }).isRequired,
  onCheckout: func.isRequired,
  totalLabel: string.isRequired,
  checkoutLabel: string.isRequired,
};

ProductGroupBlock.propTypes = ProductGroupBlockType;

export default ProductGroupBlock;
