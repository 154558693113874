import { bool, elementType, func, node, string } from "prop-types";
import { forwardRef } from "react";

import classnames from "~utils/classnames";

import classes from "./CircleButton.module.scss";

/**
 * @typedef {Object} CircleButtonProps
 * @property {React.ElementType} [as] - The component type to render as
 * @property {boolean} [isActive] - Whether the button is active
 * @property {string} [title] - The title of the button
 * @property {(event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => void} [onClick] - The click handler
 * @property {string} [href] - The href of the button
 * @property {string} [className] - Additional class names
 * @property {React.ReactNode} [children] - The children to render
 * @property {React.Ref<HTMLElement>} ref - Ref to be forwarded to the component
 */

/**
 * CircleButton Component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<CircleButtonProps> & React.RefAttributes<HTMLElement>>}
 * @example
 * <CircleButton as="div" isActive className="my-class" />
 */

const CircleButton = forwardRef(
  (
    {
      as: Component = "button",
      isActive = false,
      title,
      onClick = () => {},
      href,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <Component
        ref={ref}
        title={title}
        href={href}
        onClick={onClick}
        className={classnames(
          classes.button,
          isActive && classes.button_active,
          className,
        )}
        {...props}
      >
        {children}
      </Component>
    );
  },
);

CircleButton.propTypes = {
  // @ts-ignore
  as: elementType,
  isActive: bool,
  className: string,
  title: string,
  onClick: func,
  href: string,
  children: node,
};

CircleButton.displayName = "CircleButton";

export default CircleButton;
