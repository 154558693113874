import { bool, node, oneOf, string } from "prop-types";

import Icon from "~components/Icon";
import iconFiles from "~components/Icon/icons";
import classnames from "~utils/classnames";

import classes from "./pill.module.scss";

const Pill = ({
  isActive,
  isHovered,
  variant,
  icon,
  small,
  noTheme,
  children,
  className,
}) => {
  return (
    <div
      className={classnames(
        classes.pill,
        (isActive || isHovered) && classes.active,
        variant && classes[`variant_${variant}`],
        small && classes.small,
        noTheme && classes.disableTheming,
        className,
      )}
    >
      {icon && (
        <Icon
          className={classes.icon}
          type={icon}
          focusable={false}
          removeIconDefaultSize
        />
      )}
      {children}
      {isActive && (
        <Icon className={classes.cross} type="cross" focusable={false} />
      )}
    </div>
  );
};

Pill.propTypes = {
  isActive: bool,
  isHovered: bool,
  variant: oneOf(["light", "transparent", "dark"]),
  noTheme: bool,
  children: node.isRequired,
  icon: oneOf(Object.keys(iconFiles)),
  small: bool,
  className: string,
};

export default Pill;
