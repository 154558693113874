import { arrayOf, node, shape, string } from "prop-types";
import { useState } from "react";

import Button from "~components/Button";
import Icon from "~components/Icon";
import classnames from "~utils/classnames";

import classes from "../NeighbourhoodMap.module.scss";

const positions = {
  AMSTERDAM_OOST: { x: 670, y: 400 },
  AMSTERDAM_NOORD: { x: 700, y: 150 },
  AMSTERDAM_ZUID: { x: 490, y: 430 },
  AMSTERDAM_WEST: { x: 430, y: 260 },
  AMSTERDAM_CENTRUM: { x: 570, y: 280 },
  AMSTERDAM_NIEUW_WEST: { x: 270, y: 330 },
  AMSTERDAM_ZUID_OOST: { x: 760, y: 600 },
  WEESP: { x: 1020, y: 600 },
};

const getLinkData = (links, neighborhood) => {
  const linkData = links.find((link) => link.neighbourhood === neighborhood);
  return linkData ? { link: linkData.link, label: linkData.label } : {};
};

export const Neighborhood = ({ neighborhood, links, children }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { x, y } = positions[neighborhood];
  const { link, label } = getLinkData(links, neighborhood);

  return (
    <g
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={classnames(classes.pathGroup, {
        [classes.hovered]: isHovering,
      })}
    >
      {children}
      {isHovering && (
        <foreignObject
          x={x}
          y={y}
          width="200"
          height="200"
          style={{ pointerEvents: "none" }}
        >
          <Button
            variant="solid"
            color="white"
            size="small"
            endIcon={<Icon type="arrowRight" />}
            className={classes.link}
            href={link}
          >
            {label}
          </Button>
        </foreignObject>
      )}
    </g>
  );
};

Neighborhood.propTypes = {
  neighborhood: string.isRequired,
  links: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      link: string.isRequired,
      neighbourhood: string.isRequired,
    }),
  ).isRequired,
  children: node.isRequired,
};
