import { string } from "prop-types";
import React from "react";

import Accordion from "~components/Accordion";
import Button from "~components/Button";

import classes from "./FaqBlock.module.scss";

/**
 * Due to limitations in the Prepr components we have to define every
 * question/answer individually.
 */

const FaqBlock = ({
  title,
  ctaLabel,
  ctaUrl,
  firstQuestion,
  firstAnswer,
  secondQuestion,
  secondAnswer,
  thirdQuestion,
  thirdAnswer,
  fourthQuestion,
  fourthAnswer,
  fifthQuestion,
  fifthAnswer,
}) => {
  const items = [
    { title: firstQuestion, content: firstAnswer },
    { title: secondQuestion, content: secondAnswer },
    { title: thirdQuestion, content: thirdAnswer },
    { title: fourthQuestion, content: fourthAnswer },
    { title: fifthQuestion, content: fifthAnswer },
  ];

  const filteredItems = items.filter(({ title }) => !!title);

  return (
    <section className={classes.faqBlock}>
      <h2 className={classes.faqBlockTitle}>{title}</h2>
      <Accordion items={filteredItems} bellowType="card" />
      {ctaLabel && ctaUrl && (
        <div className={classes.cta}>
          <Button href={ctaUrl}>{ctaLabel}</Button>
        </div>
      )}
    </section>
  );
};

export const FaqBlockFragment = /* GraphQL */ `
  fragment FaqBlockFragment on FAQBlock {
    id: _id
    type: __typename
    title
    ctaLabel: cta_label
    ctaUrl: cta_url
    firstQuestion: first_question
    firstAnswer: first_answer
    secondQuestion: second_question
    secondAnswer: second_answer
    thirdQuestion: thrid_question
    thirdAnswer: third_answer
    fourthANswer: fourth_answer
    fourthQuestion: fourth_question
    fourthAnswer: fourth_answer
    fifthQuestion: fifth_question
    fifthAnswer: fifth_answer
  }
`;

export const FaqBlockType = {
  title: string,
  ctaLabel: string,
  ctaUrl: string,
  firstQuestion: string,
  firstAnswer: string,
  secondQuestion: string,
  secondAnswer: string,
  thirdQuestion: string,
  thirdAnswer: string,
  fourthQuestion: string,
  fourthAnswer: string,
  fifthQuestion: string,
  fifthAnswer: string,
};

FaqBlock.propTypes = FaqBlockType;

export default FaqBlock;
